import {
  ITask,
  ICollaborationTemplate,
  ICollaborationTemplateTask,
  DueDaysAutomation,
  DueDateAutomationSelection,
  EmployeeAutomation,
  EmployeeAutomationSelection,
  ICollaborationTemplateTaskTranslation,
  CollaborationTemplateType,
  CollaborationTemplateTaskType,
  ICollaborationTemplateTranslation
} from './types';
import * as moment from 'moment';

import translate from '@/app/utils/translate';
import {
  getEmployeeName,
  getEmployeeManagerId,
  getEmployeeHRId,
  getLoggedUserId,
} from '@/old/utils/helper';
import { getEmployee } from '../SearchEmployeeField/helpers/getEmployees';

const NO_DUE_DATE_AUTOMATION_VALUE = -999999;

const hasDueDateAutomation = (days: number): boolean => {
  return days > NO_DUE_DATE_AUTOMATION_VALUE;
};

const getDueDateAutomationDays = (days: number): number | undefined => {
  if (!hasDueDateAutomation(days) || days === 0) { return 0; }
  return days < 0 ? Math.abs(days) : days;
};

const getDueDateAutomationType = (days: number): DueDaysAutomation => {
  if (!hasDueDateAutomation(days)) { return 'NONE'; }
  if (days === 0) { return 'ON'; }
  return days < 0 ? 'BEFORE' : 'AFTER';
};

export const resolveInitialDueDate = (completionDate: Date, type: DueDaysAutomation, days: number): Date => {
  switch (type) {
    case 'ON':
      return completionDate;
    case 'AFTER':
      return moment(completionDate).add(days, 'days').toDate();
    case 'BEFORE':
      return moment(completionDate).add(-1 * days, 'days').toDate();
    default:
      return null;
  }
};

export const resolveInitialEmployeeId = (
  type: EmployeeAutomation,
  loggedInUserId: number,
  selectedEmpId: number
): number => {
  switch (type) {
    case 'LOGGED_IN_USER':
      return loggedInUserId;
    case 'LOGGED_IN_USER_MANAGER':
      return getEmployeeManagerId(loggedInUserId);
    case 'LOGGED_IN_USER_HR_RESP':
      return getEmployeeHRId(loggedInUserId);
    case 'NAMED_EMP':
    case 'SELECTED_EMP':
      return selectedEmpId;
    case 'SELECTED_EMP_MANAGER':
      return getEmployeeManagerId(selectedEmpId);
    case 'SELECTED_EMP_HR_RESP':
      return getEmployeeHRId(selectedEmpId);
    default:
      return null;
  }
};

export const resolveDueDate = (
  automationSelection: DueDateAutomationSelection,
  type: DueDaysAutomation,
  days: number,
  completionDate: Date,
  currentDueDate: Date,
): Date | undefined => {
  switch (automationSelection) {
    case 'AUTO': return resolveInitialDueDate(completionDate, type, days);
    case 'COMPL_DATE': return completionDate;
    case 'MANUAL': return currentDueDate;
    default: return undefined;
  }
};

export const resolveAssignee = (automationRule: string): string => {
  return `resolved: ${automationRule}`;
};

export const resolvePertainingTo = (automationRule: string): string => {
  return `resolved: ${automationRule}`;
};

export const getDueDateAutomationText = (type: DueDaysAutomation, days: number) => {
  switch (type) {
    case 'ON':
      return `${translate.t('label_on_completion_date')}`;
    case 'BEFORE':
      return `${translate.t('label_days_before_completion_date', {
        days,
      })}`;
    case 'AFTER':
      return `${translate.t('label_days_after_completion_date', {
        days,
      })}`;
    default:
      return '';
  }
};

export const getEmployeeAutomationText = (
  type: EmployeeAutomation,
  loggedInEmpId: number,
  selectedEmpId: number
) => {
  if (type === 'NONE') { return ''; }

  const getManagerEmpId = (empId: number) => {
    return getEmployeeManagerId(empId);
  };

  const getHrEmpId = (empId: number) => {
    return getEmployeeHRId(empId);
  };

  let name;
  let transLabel;
  switch (type) {

    case 'LOGGED_IN_USER':
      name = getEmployeeName(loggedInEmpId);
      transLabel = 'label_logged_in_user';
      break;
    case 'LOGGED_IN_USER_MANAGER':
      name = getEmployeeName(getManagerEmpId(loggedInEmpId));
      transLabel = 'label_logged_in_user_manager';
      break;
    case 'LOGGED_IN_USER_HR_RESP':
      name = getEmployeeName(getHrEmpId(loggedInEmpId));
      transLabel = 'label_logged_in_user_hr_resp';
      break;

    case 'NAMED_EMP':
    case 'SELECTED_EMP':
      name = getEmployeeName(selectedEmpId);
      transLabel = 'label_selected_emp';
      break;
    case 'SELECTED_EMP_MANAGER':
      name = getEmployeeName(getManagerEmpId(selectedEmpId));
      transLabel = 'label_selected_emp_manager';
      break;
    case 'SELECTED_EMP_HR_RESP':
      name = getEmployeeName(getHrEmpId(selectedEmpId));
      transLabel = 'label_selected_emp_hr_resp';
      break;

    default:
      name = '';
      transLabel = '';
  }

  return `${translate.t(transLabel, { name })}`;
};

export const getInitialDueDateAutomationSelection = (type: DueDaysAutomation): DueDateAutomationSelection => {
  if (type === 'NONE') {
    return 'NONE';
  }
  return 'AUTO';
};

export const getInitialEmployeeAutomationSelection = (type: EmployeeAutomation): EmployeeAutomationSelection => {
  if (type === 'NONE') {
    return 'NONE';
  }
  return 'AUTO';
};

const getDueDateAutomationForBE = (type: DueDaysAutomation, days: number): number => {
  switch (type) {
    case 'ON': return 0;
    case 'BEFORE': return -1 * days;
    case 'AFTER': return days;
    default: return NO_DUE_DATE_AUTOMATION_VALUE;
  }
};

export const mapCollaborationTemplateForUI = (data: CollaborationTemplateType): ICollaborationTemplate => {
  const tasks = !!data.fCollaborationTemplateTasks
    ? data.fCollaborationTemplateTasks.map(
      (task: CollaborationTemplateTaskType) => mapCollaborationTemplateTaskForUI(task, data.fCollaborationTemplateDefaultLanguage)
    ) : undefined;
  return {
    id: data.fCollaborationTemplateId,
    title: data.fCollaborationTemplateTitle,
    active: data.fCollaborationTemplateIsActive,
    description: data.fCollaborationTemplateDescription,
    type: data.fCollaborationTemplateTypeFK,
    language: data.fCollaborationTemplateDefaultLanguage,
    tasks,
  };
};

export const mapCollaborationTemplateForBE = (data: ICollaborationTemplate): any => {
  const tasks = data.tasks;
  return {
    fCollaborationTemplateId: data.id,
    fCollaborationTemplateTitle: data.title,
    fCollaborationTemplateIsActive: data.active,
    fCollaborationTemplateDescription: data.description,
    fCollaborationTemplateDefaultLanguage: data.language,
    fCollaborationTemplateTypeFK: data.type,
    ...(tasks ? { fCollaborationTemplateTasks: tasks.map((task) => mapCollaborationTemplateTaskForBE(task)) } : {}),
  };
};

const getTaskAssigneeForUI = (data: any): EmployeeAutomation => {
  const assigneeFromBE = data.fCollaborationTemplateTaskAutomnAssignee;
  let assigneeForUI: EmployeeAutomation = assigneeFromBE;
  if (assigneeFromBE.startsWith('NAMED_EMP')) {
    assigneeForUI = 'NAMED_EMP';
  }

  return assigneeForUI;
};

const getNamedEmployeeForUI = (data: any): { label: string; value: number } => {
  const assignee: string = data.fCollaborationTemplateTaskAutomnAssignee;

  if (assignee.startsWith('NAMED_EMP')) {
    const employeeId = data.fCollaborationTemplateTaskAutomnAssignee.match(/[0-9]+/g)[0];
    const { firstName, lastName, position, code } = getEmployee(employeeId) || {};

    return {
      label: `${firstName} ${lastName} (${position}) - ${code}`,
      value: Number(employeeId)
    };
  }

  return { label: '', value: -1 };
};

export const mapCollaborationTemplatesForUI = (data: CollaborationTemplateType[]): ICollaborationTemplate[] => {
  return data.map(item => mapCollaborationTemplateForUI(item));
};

export const mapCollaborationTemplateTaskForUI = (data: CollaborationTemplateTaskType, language?: string): ICollaborationTemplateTask => {
  return {
    id: data.fCollaborationTemplateTaskId,
    seq: data.fCollaborationTemplateTaskSeq,
    ctId: data.fCollaborationTemplateTaskTemplateFK,
    title: data.fCollaborationTemplateTaskTitle,
    description: data.fCollaborationTemplateTaskDescription,
    type: data.fCollaborationTemplateTaskTypeFK,
    language: language || '',
    dueDateAutomationDays: getDueDateAutomationDays(data.fCollaborationTemplateTaskAutomnDueDay),
    dueDateAutomationType: getDueDateAutomationType(data.fCollaborationTemplateTaskAutomnDueDay),
    assigneeAutomation: getTaskAssigneeForUI(data),
    otherNamedPerson: getNamedEmployeeForUI(data),
    pertainingToAutomation: data.fCollaborationTemplateTaskAutomnPertainingTo as EmployeeAutomation,
    visibleToPertainingTo: data.fCollaborationTemplateTaskVisibleToPertTo,
    attachmentId: data.fCollaborationTemplateTaskAttachmentId,
    attachmentName: data.fCollaborationTemplateTaskAttachmentName,
    created: {
      time: data.fCollaborationTemplateTaskCreatedTime,
      user: data.fCollaborationTemplateTaskCreatedUserFK,
    },
    updated: {
      time: data.fCollaborationTemplateTaskUpdatedTime,
      user: data.fCollaborationTemplateTaskUpdatedUserFK,
    }
  };
};

const withUploadFilename = (file: File) => {
  if (!file) { return file; }
  return Object.assign(file, { uploadName: file.name });
};

const getTaskAssigneeForBE = (data: ICollaborationTemplateTask) => {
  const namedEmployeeSelected = data.assigneeAutomation === 'NAMED_EMP' && data.otherNamedPerson;
  const namedEmployeeWithId = namedEmployeeSelected && `${data.assigneeAutomation}_[${data.otherNamedPerson.value}]`;
  const assignee = namedEmployeeSelected ? namedEmployeeWithId : data.assigneeAutomation;
  return assignee;
};

export const mapCollaborationTemplateTaskForBE = (data: ICollaborationTemplateTask): any => {
  return {
    fCollaborationTemplateTaskId: data.id,
    ...(data.seq ? { fCollaborationTemplateTaskSeq: data.seq } : {}),
    fCollaborationTemplateTaskTemplateFK: data.ctId,
    fCollaborationTemplateTaskTitle: data.title,
    fCollaborationTemplateTaskDescription: data.description || '',
    fCollaborationTemplateTaskTypeFK: data.type,
    fCollaborationTemplateTaskAutomnDueDay: getDueDateAutomationForBE(data.dueDateAutomationType, data.dueDateAutomationDays),
    fCollaborationTemplateTaskAutomnAssignee: getTaskAssigneeForBE(data),
    fCollaborationTemplateTaskAutomnPertainingTo: data.pertainingToAutomation,
    fCollaborationTemplateTaskVisibleToPertTo: data.visibleToPertainingTo,
    fCollaborationTemplateTaskAttachmentId: data.attachmentId || -1,
    fCollaborationTemplateTaskAttachmentName: data.attachmentName || '',
    file: withUploadFilename(data.newAttachment),
  };
};

export const mapCollaborationTemplateTranslationToBE = (data: ICollaborationTemplateTranslation): any => {
  return {
    fCollaborationTemplateTranslationTitle: data.title,
    fCollaborationTemplateTranslationDescription: data.description,
    fCollaborationTemplateTranslationLanguage: data.language,
  };
};

export const mapTaskTranslationToBE = (data: ICollaborationTemplateTaskTranslation): any => {
  return {
    fCollaborationTemplateTaskTranslationTitle: data.title,
    fCollaborationTemplateTaskTranslationDescription: data.description,
    fCollaborationTemplateTaskTranslationLanguage: data.language,
  };
};

export const mapNewTaskForBE = (data: ITask): any => {
  const loggedInUserId = getLoggedUserId();

  // Make sure this satisfies business rule (if other roles than pertaining to, task must be visible to employee)
  let visibleToPertainingTo;
  if (!data.pertainingToId) {
    visibleToPertainingTo = data.visibleToPertainingTo;
  } else {
    if (data.pertainingToId === loggedInUserId || data.pertainingToId === data.assigneeId) {
      visibleToPertainingTo = true;
    } else {
      visibleToPertainingTo = data.visibleToPertainingTo;
    }
  }

  return {
    fTaskAttachmentId: -1,
    fTaskAttachmentName: '',
    fTaskId: '',
    taskAudit: {
      fTaskCreatedTime: '',
      fTaskUpdatedEmpId: '',
      fTaskUpdatedTime: '',
    },
    taskCore: {
      fTaskAssigneeId: data.assigneeId,
      fTaskAssignorId: loggedInUserId,
      fTaskDescription: data.description,
      fTaskIsVisibleToPertainingTo: visibleToPertainingTo,
      fTaskPertainingToId: data.pertainingToId,
      fTaskTitle: data.title,
      fTaskType: data.type,
    },
    taskState: {
      fTaskDueDate: !data.dueDate ? '' : moment(data.dueDate).format('YYYY-MM-DD'),
      fTaskStartDate: moment().format('YYYY-MM-DD'),
      fTaskStatus: data.status,
    },
    fCollaborationTemplateTaskId: data.automationSelection.copyAttachment ? data.id : null,
    fOriginalCollaborationTemplateTaskId: data.id,
  };
};

export const getEmployeeAutomationTransLabel = (empAutomation: EmployeeAutomation) => {
  switch (empAutomation) {
    case 'SELECTED_EMP': return 'label_automation_sel_emp';
    case 'SELECTED_EMP_MANAGER': return 'label_automation_sel_emp_manager';
    case 'SELECTED_EMP_HR_RESP': return 'label_automation_sel_emp_hr_resp';
    case 'NAMED_EMP': return 'label_other_named_person';
    case 'LOGGED_IN_USER': return 'label_automation_curr_user';
    case 'LOGGED_IN_USER_MANAGER': return 'label_automation_curr_user_manager';
    case 'LOGGED_IN_USER_HR_RESP': return 'label_automation_curr_user_hr_resp';
    default: return 'label_automation_none';
  }
};
