import { HeadcountReportEntryMonth } from '@/app/components/Reporting/People/HeadCountReport';
import moment from 'moment';
import translate from '@/app/utils/translate';
import * as React from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ColumnConfig } from '@/old/utils/helper';

type PropsType = {
  data: Array<HeadcountReportEntryMonth>;
};

export const HeadCountTableChart = (props: PropsType) => {
  const { data: yearData } = props;

  const { columns, rows } = React.useMemo(() => {
    const columnsSettings: ColumnConfig[] = [
      {
        field: 'rowName',
        title: ' ',
        show: true,
        width: 170,
      },
      ...yearData.map(
        ({ date }, index): ColumnConfig => {
          return {
            field: `${index}`,
            title: moment(date, 'YYYY-MM-DD').format('MMM'),
            show: true,
          };
        },
      ),
    ];

    const totalsRow = {
      rowName: translate.t('laNumberOfEmp'),
      ...Object.fromEntries(
        yearData.map(({ totalCount }, index) => [index, totalCount]),
      ),
    };

    const joinersRow = {
      rowName: translate.t('laJoiners'),
      ...Object.fromEntries(
        yearData.map(({ joiners }, index) => [index, joiners]),
      ),
    };

    const leaversRow = {
      rowName: translate.t('laLeavers'),
      ...Object.fromEntries(
        yearData.map(({ leavers }, index) => [index, leavers]),
      ),
    };

    const netChangeRow = {
      rowName: translate.t('laNetChange'),
      ...Object.fromEntries(
        yearData.map(({ netChange }, index) => [index, netChange]),
      ),
    };

    return {
      columns: columnsSettings,
      rows: [totalsRow, joinersRow, leaversRow, netChangeRow],
    };
  }, [yearData]);

  return (
    <Grid
      data={rows}
      sortable={false}
      resizable={true}
      pageable={false}
      groupable={false}
    >
      {columns.map(
        (column: any, idx: number) =>
          column.show && (
            <GridColumn
              {...column}
              key={idx}
            />
          ),
      )}
    </Grid>
  );
};
