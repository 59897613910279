import * as React from 'react';
import { compose } from 'recompose';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField, WithStyles, withStyles,
} from '@material-ui/core';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import translate from '@/app/utils/translate';
import { ICollaborationTemplate } from '../types';

const styles = {
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
  }
};

type InitialFormData = ICollaborationTemplate | Pick<ICollaborationTemplate, Exclude<keyof ICollaborationTemplate, 'id' | 'active'>>;

type OwnProps = {
  open: boolean;
  taskTypes: any[];
  languages: any[];
  defaultLanguage: any;
  onSave: (values: ICollaborationTemplate) => void;
  onClose: () => void;

  initialValues: InitialFormData;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const WorklistEditDialog = (props: Props) => {
  const { initialValues, open, taskTypes, languages, defaultLanguage, onSave, onClose, classes } = props;

  const validationSchema = yup.object<ICollaborationTemplate>().shape({
    title: yup.string().required(translate.t('laErrorFieldRequir')).max(80, translate.t('laErrorStringMaxChar')),
    description: yup.string().max(3000, translate.t('laErrorTextMaxChar')),
    language: yup.string().required(translate.t('laErrorFieldRequir')),
    type: yup.string().required(translate.t('laErrorFieldRequir')),
  });

  const handleSave = (values: ICollaborationTemplate, actions: FormikProps<ICollaborationTemplate>) => {
    onSave(values);
    actions.resetForm();
    onClose();
  };

  if (!open || !initialValues) {
    return <></>;
  }

  const renderDialog = ({
    errors,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    values
  }: FormikProps<ICollaborationTemplate>) => {
    const handleCancel = () => {
      resetForm();
      onClose();
    };

    const handleSubmitClick = () => {
      handleSubmit();
    };

    const submitContent = !isSubmitting ? translate.t('laSave') : (
      <CircularProgress size={22} />
    );

    return (
      <Dialog
        open={open}
        onClose={!isSubmitting ? onClose : undefined}
        maxWidth="sm"
        fullWidth
        aria-labelledby="worklist-edit-dialog-title"
      >
        <DialogTitle id="worklist-edit-dialog-title">
          {(!values.id) ? translate.t('title_add_collab_template') : translate.t('title_edit_worklist')}
        </DialogTitle>
        <DialogContent>
          <Form>
            <div className={classes.fieldRow}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="type-input" shrink={true}>{translate.t('laType')}</InputLabel>
                <Field
                  id="type"
                  type="select"
                  component={Select}
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.type && touched.type)}
                  inputProps={{ name: 'type', id: 'type-input' }}
                >
                  {taskTypes.map((task: any) => (
                    <MenuItem key={task.code} value={task.code}>
                      {task.name}
                    </MenuItem>
                  ))}
                </Field>
                {(errors.type && touched.type) && (
                  <FormHelperText>{errors.type}</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="language-input" shrink={true}>{translate.t('laDefaultTemplateLanguage')}</InputLabel>
                <Field
                  id="language"
                  type="select"
                  component={Select}
                  name="language"
                  value={values.language || defaultLanguage.fComLanguageCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '200px' }}
                  error={Boolean(errors.language && touched.language)}
                  inputProps={{ name: 'language', id: 'language-input' }}
                >
                  {languages.map((lang: any) => (
                    <MenuItem key={lang.fComLanguageCode} value={lang.fComLanguageCode}>
                      {lang.fComLanguageName}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </div>
            <Field
              id="title"
              autoFocus
              required={true}
              component={TextField}
              error={Boolean(errors.title && touched.title)}
              fullWidth
              helperText={(errors.title && touched.title) && errors.title}
              label={translate.t('laTitle')}
              margin="normal"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
            />
            <Field
              id="description"
              component={TextField}
              error={Boolean(errors.description && touched.description)}
              fullWidth
              helperText={(errors.description && touched.description) && errors.description}
              label={translate.t('laDescription')}
              margin="normal"
              name="description"
              rows={2}
              rowsMax={5}
              multiline={true}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
            />
          </Form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {translate.t('laCancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting || !isValid}
            onClick={handleSubmitClick}
          >
            {submitContent}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {renderDialog}
    </Formik>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(styles, { withTheme: true })
);

export default enhance(WorklistEditDialog);
