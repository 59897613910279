import moment from 'moment/moment';

export const sortByDate = (a: any, b: any) => {
  if (moment(a.date).isBefore(moment(b.date))) {
    return -1;
  }
  if (moment(b.date).isBefore(moment(a.date))) {
    return 1;
  }

  return 0;
};
