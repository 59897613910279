import { StyleRulesCallback, Theme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const styles: StyleRulesCallback = (theme: Theme) => ({
  expansionPanelDetails: {
    padding: 0,
    display: 'block',
  },
  expansionPanelSummary: {
    justifyContent: 'space-between',
  },
  expansionPanelSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  flexBreak: {
    flexBasis: '100%',
    height: 0,
  },
  inactiveChip: {
    height: 24,
    marginLeft: theme.spacing.unit,
  },
  loading: {
    margin: theme.spacing.unit * 3,
  },
  noExpandedTools: {
    flexGrow: 1,
  },
  paperWidthMd: {
    width: '500px',
  },
  root: {
    padding: theme.spacing.unit * 2,
  },
  successSnackbar: {
    backgroundColor: green[600],
  },
  collaborationTemplate: {
    paddingTop: theme.spacing.unit * 2,
  },
  collaborationTemplateDesc: {
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    width: '100%',
  },
  collaborationTemplateHeading: {
    display: 'inline',
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  },
  collaborationTemplateMenuButtonContainer: {
    display: 'inline-block',
    flexGrow: 1,
    paddingLeft: theme.spacing.unit,
    minWidth: '56px',
  },
  typeLabel: {
    color: '#888',
    fontWeight: 500,
    minWidth: '150px',
    textAlign: 'left',
    marginLeft: '8px',
    marginRight: '8px',
  },
});

export default styles;
