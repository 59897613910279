import { Typography } from '@material-ui/core';
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import * as React from 'react';

import { ComponentType } from '@/app/components/TemplateComponents/constants';
import ColumnMenu from '@/app/components/Kendo/ColumnMenu';
import translate from '@/app/utils/translate';

import KendoReportDownloadButton from '../../../components/KendoReportDownloadButton';
import { KendoTemplateResultsCell } from '../../../components/KendoTemplateResultsCell';
import KendoAvatarCell from '../../../components/KendoAvatarCell';

const empGridEvaluationData = () => [
  {
    cell: KendoAvatarCell,
    field: 'empId',
    show: true,
    title: translate.t('laPicture'),
    width: 88,
  },
  {
    field: 'name',
    show: true,
    title: translate.t('laName'),
    width: 150,
  },
  {
    field: 'position',
    show: true,
    title: translate.t('laPosition'),
    width: 150,
  },
  {
    field: 'empNumber',
    show: true,
    title: translate.t('laEmpNumber'),
    width: 150,
  },
  {
    cell: KendoTemplateResultsCell,
    editable: false,
    field: 'evalItems',
    filterable: false,
    groupable: false,
    show: true,
    sortable: false,
    title: translate.t('laEvaluationItems'),
  },
];

type Props = {
  empData: any[];
  empGridTitle: string;
  exportFileName: string;
};

const mapSingularAppraisalResponse = (
  questionType: ComponentType | string,
  questionResponse: string,
  questionId: string,
): { [k: string]: string } => {
  switch (questionType) {
    case ComponentType.DATE:
    case ComponentType.TEXT:
    case ComponentType.SCALE:
      return {
        [questionId]: `${questionResponse}`,
      };
    default:
      return {};
  }
};

type EvaluationsQuestionResponse = {
  optionId: number;
  selfEvaluation: number;
  managerEvaluation: number;
  comment: string;
};

type CompetencyLevelQuestionResponse = {
  optionId: number;
  competencyLevel: number;
  targetLevel: number;
  comment: string;
};

const getFormattedAppraisalResponse = (
  questionType: ComponentType | string,
  questionId: string,
  optionId: string,
  questionResponse?:
    | string
    | EvaluationsQuestionResponse
    | CompetencyLevelQuestionResponse,
) => {
  const resolveVariableValue = (variableName: string) => {
    return questionResponse &&
      typeof questionResponse !== 'string' &&
      questionResponse[variableName]
      ? questionResponse[variableName]
      : '';
  };

  switch (questionType) {
    case ComponentType.CHECKBOXES:
      return {
        [`${questionId}-${optionId}`]: questionResponse ? 'x' : '',
      };
    case ComponentType.EVALUATIONS:
      return {
        [`${questionId}-${optionId}-self`]: resolveVariableValue(
          'selfEvaluation',
        ),
        [`${questionId}-${optionId}-manager`]: resolveVariableValue(
          'managerEvaluation',
        ),
        [`${questionId}-${optionId}-comment`]: resolveVariableValue('comment'),
      };
    case ComponentType.COMPETENCY_LEVEL:
      return {
        [`${questionId}-${optionId}-competency`]: resolveVariableValue(
          'competencyLevel',
        ),
        [`${questionId}-${optionId}-target`]: resolveVariableValue(
          'targetLevel',
        ),
        [`${questionId}-${optionId}-comment`]: resolveVariableValue('comment'),
      };
    default:
      throw new Error(`Can't handle question type: ${questionType}`);
  }
};

const unbundleGroupedAppraisalResponses = (
  questionType: ComponentType,
  questionGroupedResponses: any,
  questionId: string,
  questionFallback?: any,
): { [k: string]: string } => {
  if (questionType === ComponentType.FILE) {
    return {
      [questionId]: questionGroupedResponses
        ? questionGroupedResponses.attachmentName
        : '',
    };
  }

  const hasResponses =
    questionGroupedResponses && Array.isArray(questionGroupedResponses);
  if (questionFallback && !hasResponses) {
    return questionFallback.reduce(
      (acc: { [k: string]: string }, questionOption: any) => ({
        ...acc,
        ...getFormattedAppraisalResponse(
          questionType,
          questionId,
          questionOption.id,
        ),
      }),
      {},
    );
  } else if (!hasResponses) {
    return {};
  }

  return questionGroupedResponses.reduce(
    (acc: { [k: string]: string }, questionResponse: any) => ({
      ...acc,
      ...getFormattedAppraisalResponse(
        questionType,
        questionId,
        [ComponentType.EVALUATIONS, ComponentType.COMPETENCY_LEVEL].includes(
          questionType,
        )
          ? questionResponse.optionId
          : questionResponse,
        questionResponse,
      ),
    }),
    {},
  );
};

const mapDropdownResponse = (
  responseValue: Number, exportDataItem: any, componentId: number, appraisalRespColumnId: string
): { [k: string]: string } => {
  const components = exportDataItem.template.sections.map((section: any) => section.components).flat();
  const component = components.find((comp: any) => comp.id === componentId);
  const option = component.options.find((optn: any) => optn.id === responseValue);

  return {
    [appraisalRespColumnId]: option.label,
  };
};

const mapExportData = (exportData: any[], questionExportColumns: any[]) =>
  exportData.map((exportDataItem: any) =>
    questionExportColumns.reduce(
      (acc: any, column) => {
        const found = exportDataItem.evalItems.responses.find(
          (resp: any) => resp.componentId === column.id,
        );

        const appraisalRespColumn = {
          type: column.type,
          response: found ? found.response : '',
          id: column.id,
        };

        if (
          [
            ComponentType.CHECKBOXES,
            ComponentType.EVALUATIONS,
            ComponentType.COMPETENCY_LEVEL,
            ComponentType.FILE,
          ].includes(appraisalRespColumn.type)
        ) {
          const unbundledGroupAnswers = unbundleGroupedAppraisalResponses(
            appraisalRespColumn.type,
            appraisalRespColumn.response,
            appraisalRespColumn.id,
            found ? undefined : column.options,
          );

          return {
            ...acc,
            ...unbundledGroupAnswers,
          };
        }

        if (appraisalRespColumn.type === ComponentType.DROPDOWN) {
          const dropdownMappedValues = mapDropdownResponse(found.response[0], exportDataItem, found.componentId, appraisalRespColumn.id);

          return {
            ...acc,
            ...dropdownMappedValues,
          };

        }

        const singularResponse = mapSingularAppraisalResponse(
          appraisalRespColumn.type,
          appraisalRespColumn.response,
          appraisalRespColumn.id,
        );

        return {
          ...acc,
          ...singularResponse,
        };
      },
      {
        ...exportDataItem,
        sheets: { rows: { cells: { wrap: true } } },
      },
    ),
  );

const mapExportGridHeader = (column: any) => {
  switch (column.type) {
    case ComponentType.CHECKBOXES: {
      const colOptionComponents = column.options.map((colOption: any) => {
        return (
          <ExcelExportColumn
            key={`${column.id}-${colOption.id}`}
            field={`${column.id}-${colOption.id}`}
            title={colOption.label}
          />
        );
      });

      return (
        <ExcelExportColumnGroup
          key={column.id}
          title={column.name}
          headerCellOptions={{
            textAlign: 'center',
          }}
        >
          {column.options.length === 1
            ? colOptionComponents[0]
            : colOptionComponents}
        </ExcelExportColumnGroup>
      );
    }
    case ComponentType.EVALUATIONS: {
      const colGroupComponents = column.options.map((colOption: any) => (
        <ExcelExportColumnGroup
          key={`${column.id}-${colOption.id}`}
          title={colOption.label}
          headerCellOptions={{
            textAlign: 'center',
          }}
        >
          <ExcelExportColumn
            key={`${column.id}-${colOption.id}-self`}
            field={`${column.id}-${colOption.id}-self`}
            title={translate.t('laSelfEvaluation')}
          />
          <ExcelExportColumn
            key={`${column.id}-${colOption.id}-manager`}
            field={`${column.id}-${colOption.id}-manager`}
            title={translate.t('laMgrEvaluation')}
          />
          <ExcelExportColumn
            key={`${column.id}-${colOption.id}-comment`}
            field={`${column.id}-${colOption.id}-comment`}
            title={translate.t('laComment')}
          />
        </ExcelExportColumnGroup>
      ));

      return (
        <ExcelExportColumnGroup
          key={column.id}
          title={column.name}
          headerCellOptions={{
            textAlign: 'center',
          }}
        >
          {column.options.length === 1
            ? colGroupComponents[0]
            : colGroupComponents}
        </ExcelExportColumnGroup>
      );
    }
    case ComponentType.COMPETENCY_LEVEL: {
      const colGroupComponents = column.options.map((colOption: any) => (
        <ExcelExportColumnGroup
          key={`${column.id}-${colOption.id}`}
          title={colOption.label}
          headerCellOptions={{
            textAlign: 'center',
          }}
        >
          <ExcelExportColumn
            key={`${column.id}-${colOption.id}-competency`}
            field={`${column.id}-${colOption.id}-competency`}
            title={translate.t('laSkillLevel')}
          />
          <ExcelExportColumn
            key={`${column.id}-${colOption.id}-target`}
            field={`${column.id}-${colOption.id}-target`}
            title={translate.t('laTargetLevel')}
          />
          <ExcelExportColumn
            key={`${column.id}-${colOption.id}-comment`}
            field={`${column.id}-${colOption.id}-comment`}
            title={translate.t('laComment')}
          />
        </ExcelExportColumnGroup>
      ));

      return (
        <ExcelExportColumnGroup
          key={column.id}
          title={column.name}
          headerCellOptions={{
            textAlign: 'center',
          }}
        >
          {column.options.length === 1
            ? colGroupComponents[0]
            : colGroupComponents}
        </ExcelExportColumnGroup>
      );
    }
    default:
      return (
        <ExcelExportColumn
          key={column.id}
          field={column.id}
          title={column.name}
        />
      );
  }
};

const EmployeeAppraisalGrid = (props: Props) => {
  const { exportFileName, empData, empGridTitle } = props;
  const [columns, setColumns] = React.useState<any[]>(empGridEvaluationData());
  const [dataState = { skip: 0, take: 9 }, setDataState] = React.useState();
  const exportRef: React.LegacyRef<ExcelExport> = React.useRef();
  const gridRef: React.LegacyRef<Grid> = React.useRef();

  const onColumnsSubmit = (columnsState: any) => {
    setColumns(columnsState);
  };

  const createDataState = (dataStateProp: any) => {
    return {
      dataState: setDataState(dataStateProp),
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    return createDataState(event.data);
  };

  const getQuestionExportColumns = () => {
    const selectedTemplateSectionIds =
      empData && empData.length
        ? Object.keys(empData[0].selectedTemplateFields)
        : [];

    return selectedTemplateSectionIds.reduce(
      (acc: any[], sectionId: string) => {
        if (!empData || !empData[0] || !empData[0].template.sections) {
          return acc;
        }

        const foundSection = empData[0].template.sections.find(
          (section: any) => {
            return section.id === sectionId;
          },
        );

        if (!foundSection) {
          return acc;
        }

        const extraColumns = foundSection.components.filter((comp: any) =>
          empData[0].selectedTemplateFields[sectionId].selectedFields.includes(
            comp.id,
          ),
        );

        return acc.concat(extraColumns);
      },
      [],
    );
  };

  const getNormalExcelExportColumns = () =>
    (gridRef.current ? gridRef.current.columns : columns)
      .filter(col => col.field !== 'evalItems')
      .map(col => {
        const title =
          col.field === 'empId' ? translate.t('laEmpId') : col.title;

        return (
          <ExcelExportColumn key={col.field} field={col.field} title={title} />
        );
      });

  const getGroupedExcelExportColumns = () =>
    getQuestionExportColumns().map(mapExportGridHeader);

  const saveGridData = () => {
    const questionExportColumns = getQuestionExportColumns();
    const exportData = mapExportData(
      process(empData, {
        ...dataState,
        // Export all the records
        skip: 0,
        take: undefined,
      }).data,
      questionExportColumns,
    );

    exportRef.current.save(exportData);
  };

  return (
    <>
      <ExcelExport ref={exportRef} fileName={exportFileName}>
        {getNormalExcelExportColumns()}
        {getGroupedExcelExportColumns()}
      </ExcelExport>
      <Grid
        data={process(empData, dataState)}
        onDataStateChange={dataStateChange}
        {...dataState}
        sortable={true}
        resizable={true}
        pageable={true}
        groupable={true}
        filterable={false}
        ref={gridRef}
      >
        <GridToolbar>
          <Typography variant="h6" gutterBottom color="primary">
            {empGridTitle}
          </Typography>
          <KendoReportDownloadButton onClick={saveGridData} />
        </GridToolbar>
        {columns.map(
          (column: any, idx: number) =>
            column.show && (
              <GridColumn
                {...column}
                key={idx}
                columnMenu={
                  column.field !== 'evalItems'
                    ? (otherProps: any) => (
                        <ColumnMenu
                          {...otherProps}
                          columns={columns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )
                    : null
                }
              />
            ),
        )}
      </Grid>
    </>
  );
};

export default EmployeeAppraisalGrid;
