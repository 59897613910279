interface IEnumGroupConfig {
  [name: string]: IEnumGroupConfigItem;
}

interface IEnumGroupConfigItem {
  displayAreas: string[];
  transKey?: string;
  transPluralKey?: string;
  hasDetails?: boolean;
  hasTags?: boolean;
  isCountryBased?: boolean;
  order: number;
  customizableUiLabelName?: string;
}

const createConfig = ({
  displayAreas,
  transKey,
  transPluralKey,
  hasDetails = false,
  hasTags = false,
  isCountryBased = false,
  order,
  customizableUiLabelName = '',
}: IEnumGroupConfigItem) => ({
  displayAreas,
  transKey,
  transPluralKey,
  hasDetails,
  hasTags,
  isCountryBased,
  order,
  customizableUiLabelName,
});

const ENUM_GROUP_CONFIG: IEnumGroupConfig = {
  EMPLOYEE_POSITION: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laEmployeePosition',
    transPluralKey: 'laEmployeePositions',
    isCountryBased: true,
    order: 1,
  }),
  EMPLOYMENT_STATUS: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laEmploymentStatus',
    transPluralKey: 'laEmploymentStatuses',
    isCountryBased: true,
    order: 2,
  }),
  EMPLOYMENT_TYPE: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laEmploymentType',
    transPluralKey: 'laEmploymentTypes',
    isCountryBased: true,
    order: 3,
  }),
  JOB_GRADE: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laJobGrade',
    transPluralKey: 'laJobGrades',
    isCountryBased: true,
    order: 4,
  }),
  EQUIPMENT_TYPE: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laEquipmentType',
    transPluralKey: 'laEquipmentTypes',
    order: 5,
  }),
  VACATION_BALANCE_LABEL: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laVacBal',
    transPluralKey: 'laVacBal',
    order: 10,
  }),
  VACATION_BANK_LABEL: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laVacationBank',
    transPluralKey: 'laVacationBank',
    order: 11,
  }),
  ABSENCE_LONG_REASON: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laAbsenceReason',
    transPluralKey: 'laLongTermAbsences',
    isCountryBased: true,
    hasDetails: true,
    order: 12,
  }),
  ABSENCE_SHORT_REASON: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laAbsenceReason',
    transPluralKey: 'laShortTermAbsences',
    isCountryBased: true,
    hasDetails: true,
    order: 13,
  }),
  ABSENCE_PREVIOUS_REASON: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laAbsenceReason',
    transPluralKey: 'laPreviousAbsences',
    isCountryBased: true,
    hasDetails: true,
    order: 14,
  }),
  DOCUMENT_TYPE: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laDocType',
    transPluralKey: 'laDocTypes',
    isCountryBased: true,
    order: 15,
  }),
  EMPL_FIXED_TERM_REASON: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laReasonFixTerm',
    transPluralKey: 'laReasonsFixTerm',
    isCountryBased: true,
    order: 16,
  }),
  EMPL_TERMINATION_REASON: createConfig({
    displayAreas: ['PEOPLE'],
    transKey: 'laTerminationReason',
    transPluralKey: 'laTerminationReasons',
    isCountryBased: true,
    order: 17,
  }),
  ALLOWANCE_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laAllowanceType',
    transPluralKey: 'laAllowanceTypes',
    isCountryBased: true,
    order: 1,
  }),
  BENEFIT_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laBenefitType',
    transPluralKey: 'laBenefitTypes',
    isCountryBased: true,
    order: 2,
  }),
  REW_BILLABLE_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laBillableType',
    transPluralKey: 'laBillableTypes',
    isCountryBased: true,
    order: 3,
  }),
  REW_BILLABLE_PROJECT: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laBillableProjectType',
    transPluralKey: 'laBillableProjectTypes',
    isCountryBased: true,
    order: 4,
  }),
  INCENTIVE_LONG_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laLongTermIncentiveType',
    transPluralKey: 'laLongTermIncentiveTypes',
    isCountryBased: true,
    order: 5,
  }),
  INCENTIVE_SHORT_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laShortTermIncentiveType',
    transPluralKey: 'laShortTermIncentiveTypes',
    isCountryBased: true,
    order: 6,
  }),
  HOURLY_SALARY_COMPONENT: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laHourlySalaryComponents',
    transPluralKey: 'laHourlySalaryComponents',
    order: 7,
  }),
  REW_SALARY_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laSalaryType',
    transPluralKey: 'laSalaryType',
    isCountryBased: true,
    order: 8,
  }),
  REW_SALARY_CLASS: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laSalaryClass',
    transPluralKey: 'laSalaryClasses',
    isCountryBased: true,
    order: 9,
  }),
  SALARY_CHANGE_REASON: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laSalaryChangeReason',
    transPluralKey: 'laSalaryChangeReason',
    isCountryBased: true,
    order: 10,
  }),
  ACKNOWLEDGEMENT_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laAcknowledgementType',
    transPluralKey: 'laAcknowledgementTypes',
    isCountryBased: true,
    order: 11,
  }),
  DOCUMENT_REW_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laDocType',
    transPluralKey: 'laDocTypes',
    isCountryBased: true,
    order: 12,
  }),
  WORKING_HOURS_TYPE: createConfig({
    displayAreas: ['REWARDING'],
    transKey: 'laWorkingHoursType',
    transPluralKey: 'laWorkingHoursTypes',
    isCountryBased: true,
    order: 13,
  }),
  TIMESHEET_WORK_TYPE: createConfig({
    displayAreas: ['WORKTIME'],
    transKey: 'laWorkReportingType',
    transPluralKey: 'laWorkReportingTypes',
    isCountryBased: true,
    order: 1,
  }),
  TIMESHEET_NON_WORK_TYPE: createConfig({
    displayAreas: ['WORKTIME'],
    transKey: 'laNonWorkReportingType',
    transPluralKey: 'laNonWorkReportingTypes',
    isCountryBased: true,
    order: 2,
  }),
  TIMESHEET_CORRECTION_TYPE: createConfig({
    displayAreas: ['WORKTIME'],
    transKey: 'laCorrectionReportingType',
    transPluralKey: 'laCorrectionReportingTypes',
    isCountryBased: true,
    order: 3,
  }),
  PERF_EVAL_RESULT: createConfig({
    displayAreas: ['PERFORMANCE'],
    transKey: 'laPerfEvalResult',
    transPluralKey: 'laPerformanceEvaluation',
    order: 1,
  }),
  PERF_STATUS_DISCUSSION: createConfig({
    displayAreas: ['PERFORMANCE'],
    transKey: 'laPerfStatusDiscussion',
    transPluralKey: 'laStatusDisc',
    order: 2,
  }),
  PERF_SELFEVAL_EVAL_QUESTION: createConfig({
    displayAreas: ['PERFORMANCE'],
    transKey: 'laMotivationQuestion',
    transPluralKey: 'laSelfEvaluation',
    order: 3,
    customizableUiLabelName: 'laSelfEvaluation',
    hasDetails: true,
  }),
  PERF_SELFEVAL_WELLBEING_QUESTION: createConfig({
    displayAreas: ['PERFORMANCE'],
    transKey: 'laCapacityQuestion',
    transPluralKey: 'laCapaWell',
    order: 4,
    customizableUiLabelName: 'laCapaWell',
  }),
  PERF_NOTES_TYPE: createConfig({
    displayAreas: ['PERFORMANCE'],
    transKey: 'laNotes',
    transPluralKey: 'laNotes',
    order: 5,
  }),
  PERF_MANAGER_FEEDBACK_TYPE: createConfig({
    displayAreas: ['PERFORMANCE'],
    transKey: 'laFeedMan',
    transPluralKey: 'laFeedMan',
    order: 6,
    customizableUiLabelName: 'laFeedMan',
  }),
  PERF_EMPLOYEE_FEEDBACK_TYPE: createConfig({
    displayAreas: ['PERFORMANCE'],
    transKey: 'laFeedEmp',
    transPluralKey: 'laFeedEmp',
    order: 7,
    customizableUiLabelName: 'laFeedEmp',
  }),
  PROJECT_TYPE: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laProjectType',
    transPluralKey: 'laProjectType',
    order: 1,
  }),
  INTERNAL_TRAINING_TYPE: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laInternalTraining',
    transPluralKey: 'laInternalTraining',
    order: 5,
  }),
  EXTERNAL_TRAINING_TYPE: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laExternalTraining',
    transPluralKey: 'laExternalTraining',
    order: 6,
  }),
  EXTERNAL_POSITION_TYPE: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laExperience',
    transPluralKey: 'laExperience',
    order: 7,
    hasDetails: true,
  }),
  TRAINING_CLASS: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'enum_training_class',
    transPluralKey: 'enum_training_class',
    order: 6.5,
  }),
  DEV_FOCUS: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laDevFocusQuestion',
    transPluralKey: 'laDevFocusQuestions',
    order: 7,
  }),
  DEV_ACTIVITIES_METHOD: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laDevActivityMethod',
    transPluralKey: 'laDevActivitiesMethods',
    order: 8,
  }),
  DEV_CAREER_ASPIRATION: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laDevCareerAspirationQuestion',
    transPluralKey: 'laDevCareerAspirationQuestions',
    order: 9,
  }),
  DEV_ADDITIONAL_PLAN: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laDevAdditionalPlanQuestion',
    transPluralKey: 'laDevAdditionalPlanQuestions',
    order: 10,
  }),
  CORE_COMPETENCY: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laCoreCompetencies',
    transPluralKey: 'laCoreCompetencies',
    order: 2,
    hasDetails: true,
  }),
  DEV_COMPETENCY_POS_RELATED: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laPositionCompetencies',
    transPluralKey: 'laPositionCompetencies',
    hasTags: true,
    order: 3,
    hasDetails: true,
  }),
  DEV_COMPETENCY_PROFESSIONAL: createConfig({
    displayAreas: ['DEVELOPMENT'],
    transKey: 'laProfessionalCompetencies',
    transPluralKey: 'laProfessionalCompetencies',
    hasTags: true,
    order: 4,
    hasDetails: true,
  }),
  COLLAB_TASK_TYPE: createConfig({
    displayAreas: ['COLLABORATION'],
    transKey: 'laChecklistAndTemplateTypes',
    transPluralKey: 'laChecklistAndTemplateTypes',
    order: 1,
  }),
  COLLAB_TASK_STATUS: createConfig({
    displayAreas: ['COLLABORATION'],
    transKey: 'laTaskStatus',
    transPluralKey: 'laTaskStatus',
    order: 2,
  }),
  UNIT_TYPE: createConfig({
    displayAreas: ['ORGANIZATION'],
    transKey: 'laUnitType',
    transPluralKey: 'laOrgUnitTypes',
    order: 2,
  }),
  JOB_APPLICATION_SOURCE: createConfig({
    displayAreas: ['RECRUITING'],
    transKey: 'laAppSource',
    transPluralKey: 'laAppSources',
    order: 1,
  }),
  PLANNING_SUCCESSION_READINESS: createConfig({
    displayAreas: ['PLANNING'],
    transKey: 'laSucceStatus',
    transPluralKey: 'laSucceStatus',
    order: 1,
  }),
  APPRAISAL_TEMPLATE_TAGS: createConfig({
    displayAreas: ['APPRAISAL_TEMPLATE_TAGS'],
    transKey: 'laTemplateTag',
    transPluralKey: 'laTemplateTags',
    order: 1,
  }),
  GOALS_TEMPLATE_TAGS: createConfig({
    displayAreas: ['GOALS_TEMPLATE_TAGS'],
    transKey: 'laTemplateTag',
    transPluralKey: 'laTemplateTags',
    order: 1,
  }),
  JOB_REQ_TEMPLATE_TAGS: createConfig({
    displayAreas: ['JOB_REQ_TEMPLATE_TAGS'],
    transKey: 'laTemplateTag',
    transPluralKey: 'laTemplateTags',
    order: 1,
  }),
  CUSTOM_ENTITY_COST_CENTER: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laCostCenterCustomField',
    transPluralKey: 'laCostCenterCustomFields',
    isCountryBased: true,
    order: 1,
  }),
  CUSTOM_ENTITY_BILLABLE_HOURS_PROPOSAL: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laBillableHoursCustomField',
    transPluralKey: 'laBillableHoursCustomFields',
    isCountryBased: true,
    order: 1,
  }),
  CUSTOM_ENTITY_EMPLOYEE: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laPersonalData',
    transPluralKey: 'laPersonalData',
    isCountryBased: true,
    order: 1,
    hasDetails: true,
  }),
  CUSTOM_ENTITY_EMPLOYEE_POS: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laPositionCustomData',
    transPluralKey: 'laPositionCustomData',
    isCountryBased: true,
    order: 1,
    hasDetails: true,
  }),
  CUSTOM_ENTITY_DECISION_REGISTRY: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laDecisionRegistry',
    transPluralKey: 'laDecisionRegistry',
    isCountryBased: true,
    order: 3,
    hasDetails: true,
  }),
  CUSTOM_ENTITY_EMPLOYMENT: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laRewardingData',
    transPluralKey: 'laRewardingData',
    isCountryBased: true,
    order: 1,
  }),
  CUSTOM_ENTITY_TIMESHEET: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laOptionalFields',
    transPluralKey: 'laOptionalFields',
    isCountryBased: true,
    order: 1,
  }),
  CUSTOM_ENTITY_JOB_APPLICATION: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laApplicationData',
    transPluralKey: 'laApplicationData',
    order: 1,
  }),
  CUSTOM_ENTITY_UNIT_POSITION: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laUnitPositionCustomField',
    transPluralKey: 'laUnitPositionCustomFields',
    order: 1,
  }),
  CUSTOM_ENTITY_EMPLOYMENT_DETAILS: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laEmploymentDetailsCustomField',
    transPluralKey: 'laEmploymentDetailsCustomFields',
    order: 1,
  }),
  CUSTOM_ENTITY_EMPLOYMENT_DETAILS_PROPOSAL: createConfig({
    displayAreas: ['CUSTOM_FIELDS'],
    transKey: 'laEmploymentDetailsProposalCustomField',
    transPluralKey: 'laEmploymentDetailsCustomFields',
    order: 1,
  }),
};

export default ENUM_GROUP_CONFIG;
