import * as React from 'react';
import {
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  WithStyles,
  withStyles,
  Button,
} from '@material-ui/core';
import { ICollaborationTemplateTask, EmployeeAutomation, ICollaborationTemplateTaskTranslation } from '../types';
import translate from '@/app/utils/translate';

import styles from './taskTable/styles';
import TaskEditDialog from './TaskEditDialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { getEnums, downloadAttachment } from '@/old/utils/helper';
import {
  getEmployeeAutomationTransLabel,
  getDueDateAutomationText,
} from '../service';
import API from '@/app/api/internalAPIs';
import TaskTranslationDialog from './taskTranslationDialog/TaskTranslationDialog';

type OwnProps = {
  tasks: ICollaborationTemplateTask[];
  onDelete: (id: number) => void;
  onUpdate: (values: ICollaborationTemplateTask) => void;
  onTranslate: (ctId: number, taskId: number, values: ICollaborationTemplateTaskTranslation) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const sortByName = (a: any, b: any) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const getTaskTypes = () => {
  return getEnums('COLLAB_TASK_TYPE').filter((item: any) => item.isActive);
};

const TaskTable = (props: Props) => {
  const { classes, tasks, onDelete, onUpdate, onTranslate } = props;
  const [rowMenuAnchorEl, setRowMenuAnchorEl] = React.useState<any>();
  const [taskEditDialogOpen, setTaskEditDialogOpen] = React.useState(false);
  const [taskDeleteConfirmOpen, setTaskDeleteConfirmOpen] = React.useState(
    false,
  );
  const [translationDialogOpen, setTranslationDialogOpen] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState<ICollaborationTemplateTask | undefined>();
  const taskTypes = getTaskTypes();

  const getAutomation = (task: ICollaborationTemplateTask): React.ReactNode => {
    const dueDateString =
      task.dueDateAutomationType === 'NONE'
        ? ''
        : `${translate.t('fTaskDueDate')}: ${getDueDateAutomationText(
            task.dueDateAutomationType,
            task.dueDateAutomationDays,
          )}`;

    const getEmployeeAutomation = (
      key: EmployeeAutomation,
      automationType: string,
    ): string => {
      if (key === 'NONE') {
        return '';
      } else {
        return `${automationType} ${translate.t(
          getEmployeeAutomationTransLabel(key),
        )}`;
      }
    };

    const assigneeString = getEmployeeAutomation(
      task.assigneeAutomation,
      `${translate.t('laAssignee')}:`,
    );
    const pertainingToString = getEmployeeAutomation(
      task.pertainingToAutomation,
      `${translate.t('laPertaining')}:`,
    );

    return (
      <>
        <div>{dueDateString}</div>
        <div>{assigneeString}</div>
        <div>{pertainingToString}</div>
      </>
    );
  };

  const getTaskTypeTranslation = (type: string) => {
    const taskType = taskTypes.find((item: any) => item.code === type);
    if (!taskType) {
      return '';
    }
    return taskType.name;
  };

  const handleDelete = React.useCallback(() => {
    onDelete(selectedTask.id);
  }, [selectedTask]);

  const handleUpdate = React.useCallback(async (values: ICollaborationTemplateTask) => {
    await onUpdate(values);
  }, []);
      
  const handleTranslationDialogClose = () => {
    setTranslationDialogOpen(false);
  };

  const renderTasks = () => {
    const sortBySequence = (a: ICollaborationTemplateTask, b: ICollaborationTemplateTask) => {
      return a.seq - b.seq;
    };

    return tasks.sort(sortBySequence).map((task: ICollaborationTemplateTask) => {
      const handleRowMenuOpen = (e: React.SyntheticEvent) => {
        setRowMenuAnchorEl(e.target);
        setSelectedTask(task);
      };

      const handleStartEdit = (_e: React.SyntheticEvent) => {
        setTaskEditDialogOpen(true);
        setRowMenuAnchorEl(undefined);
      };

      const handleStartDelete = () => {
        setTaskDeleteConfirmOpen(true);
        setRowMenuAnchorEl(undefined);
      };

      const handleTranslationStart = () => {
        setTranslationDialogOpen(true);
        setRowMenuAnchorEl(undefined);
      };

      const rowMenu = !!rowMenuAnchorEl && (
        <Menu
          open={Boolean(rowMenuAnchorEl)}
          anchorEl={rowMenuAnchorEl}
          onClose={() => setRowMenuAnchorEl(undefined)}
        >
          <MenuItem onClick={handleStartEdit}>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            {translate.t('laEdit')}
          </MenuItem>
          <MenuItem onClick={handleTranslationStart}>
            <ListItemIcon>
              <Icon>translate</Icon>
            </ListItemIcon>
            {translate.t('laTranslate')}
          </MenuItem>
          <MenuItem onClick={handleStartDelete}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            {translate.t('laDelete')}
          </MenuItem>
        </Menu>
      );

      const downloadAttButton =
        task.attachmentId > -1 && task.attachmentName !== '' && (
          <Button
            className={classes.attButton}
            variant="text"
            onClick={() =>
              downloadAttachment(
                API.collaborationTemplates.taskAttachment(task.id, task.attachmentId),
              )
            }
          >
            <Icon fontSize="small">attach_file</Icon>
            {task.attachmentName}
          </Button>
        );

      return (
        <React.Fragment key={`task${task.id}`}>
          <TableRow>
            {rowMenu}
            <TableCell>{task.title}</TableCell>
            <TableCell>{getTaskTypeTranslation(task.type)}</TableCell>
            <TableCell>{getAutomation(task)}</TableCell>
            <TableCell>{downloadAttButton}</TableCell>
            <TableCell align="right">
              <IconButton onClick={handleRowMenuOpen}>
                <Icon fontSize="small">more_vert</Icon>
              </IconButton>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    });
  };

  if (!tasks || tasks.length === 0) {
    return (
      <div className={classes.noData}>
        {translate.t('text_no_tasks_on_worklist')}
      </div>
    );
  }

  const hidePertainingTo = () => {
    const { pertainingToAutomation, visibleToPertainingTo } = selectedTask;
    const isDefaultValues = pertainingToAutomation === 'SELECTED_EMP' && visibleToPertainingTo;

    // TODO Verify when & how Pertaining To fields should be hidden from Task Editing
    // const pertainingToDeprecationDate = moment('2025-03-01', 'YYYY-MM-DD').startOf('day');
    // const isNotOldTask: boolean = !!created.hasOwnProperty('time') ? moment(created.time).isSameOrAfter(pertainingToDeprecationDate) : false;
    const isNotOldTask = false;

    return isDefaultValues && isNotOldTask;
  };

  const taskDialogs = !!selectedTask && (
    <>
      <TaskEditDialog
        open={taskEditDialogOpen}
        initialValues={selectedTask}
        taskTypes={taskTypes.sort(sortByName)}
        onSave={handleUpdate}
        onClose={() => setTaskEditDialogOpen(false)}
        hidePertainingTo={hidePertainingTo()}
      />
      <ConfirmDialog
        open={taskDeleteConfirmOpen}
        text={translate.t('text_confirm_worklist_task_delete', {
          title: selectedTask.title,
        })}
        customOk={translate.t('laDelete')}
        onOk={handleDelete}
        onClose={() => setTaskDeleteConfirmOpen(false)}
      />
      <TaskTranslationDialog
        open={translationDialogOpen}
        onCancel={handleTranslationDialogClose}
        task={selectedTask}
        onClose={() => setTranslationDialogOpen(false)}
        onSave={onTranslate}
      />
    </>
  );

  return (
    <>
      {taskDialogs}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{translate.t('table_col_title')}</TableCell>
            <TableCell>{translate.t('table_col_type')}</TableCell>
            <TableCell>{translate.t('table_col_automation')}</TableCell>
            <TableCell>{translate.t('table_col_attachment')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{renderTasks()}</TableBody>
      </Table>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(TaskTable);
