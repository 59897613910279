import * as React from 'react';
import {
  Divider,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@material-ui/core';
import translate from '@/app/utils/translate';

type OwnProps = {
  anchorEl: HTMLElement;
  isTemplateActive: boolean;
  templateHasTasks: boolean;

  onActivate: (e: React.SyntheticEvent) => void;
  onDeactivate: (e: React.SyntheticEvent) => void;
  onRename: (e: React.SyntheticEvent) => void;
  onDelete: (e: React.SyntheticEvent) => void;
  onClose: (e: React.SyntheticEvent) => void;
  setTranslationDialogOpen: (open: boolean) => void;
  setCollaborationTemplateMenuAnchorEl: (anchorEl: HTMLElement) => void;
};
type Props = OwnProps;

const WorklistMenu = (props: Props) => {
  const {
    anchorEl,
    isTemplateActive,
    templateHasTasks,
    onActivate,
    onDeactivate,
    onRename,
    onDelete,
    onClose,
    setTranslationDialogOpen,
    setCollaborationTemplateMenuAnchorEl,
  } = props;

  const menuItemToggleActiveState = isTemplateActive ? (
    <MenuItem onClick={onDeactivate}>
      <ListItemIcon><Icon>block</Icon></ListItemIcon>
      {translate.t('label_deactivate')}
    </MenuItem>
  ) : (
    <MenuItem onClick={onActivate} disabled={!templateHasTasks}>
      <ListItemIcon><Icon>check_circle</Icon></ListItemIcon>
      {translate.t('label_activate')}
    </MenuItem>
  );

  const handleTranslationDialogOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setTranslationDialogOpen(true);
    setCollaborationTemplateMenuAnchorEl(undefined);
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} onClick={(e) => e.stopPropagation()}>
      <MenuItem onClick={onRename}>
        <ListItemIcon><Icon>edit</Icon></ListItemIcon>
        {translate.t('label_edit_worklist')}
      </MenuItem>
      <MenuItem onClick={handleTranslationDialogOpen}>
        <ListItemIcon>
          <Icon>translate</Icon>
        </ListItemIcon>
        {translate.t('laTranslate')}
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <ListItemIcon><Icon>delete</Icon></ListItemIcon>
        {translate.t('label_delete_worklist')}
      </MenuItem>
      <Divider />
      {menuItemToggleActiveState}
    </Menu>
  );
};

export default WorklistMenu;
