import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const templateTranslationDialogStyles: StyleRulesCallback = () => ({
    root: {
        padding: '25px 25px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) !important',
        display: 'flex',
        width: '400px',
        maxHeight: '400px',
        overflow: 'auto',
        overflowX: 'hidden',

        '& > form': {
            width: '100%',
        },
    },
    taskDetailField: {
        marginTop: '20px',
    },
    loadErrorContainer: {
        textAlign: 'center',
    },
    buttonsWrapper: {
        textAlign: 'right',
        paddingTop: '20px'
    },
});

export default templateTranslationDialogStyles;