import * as React from 'react';
import { compose } from 'recompose';
import { BarExtendedDatum } from '@nivo/bar';
import { Typography, withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core';
import translate from '@/app/utils/translate';
import { COLORS } from '../JoinersLeaversChart';

const styles: StyleRulesCallback = () => ({
  box: {
    display: 'inline-block',
    float: 'left',
    width: 16,
    height: 16,
    marginRight: 8
  },
  joinersBox: {
    backgroundColor: COLORS.JOINERS,
  },
  leaversBox: {
    backgroundColor: COLORS.LEAVERS,
  },
  netBox: {
    backgroundColor: COLORS.NET,
  },
});

type OwnProps = {
  datum: BarExtendedDatum;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const BarTooltip = ({
  classes,
  datum,
}: Props) => {
  return (
    <>
      <Typography variant="subtitle2">{datum.data.label}</Typography>
      <div>
        <div className={[classes.box, classes.joinersBox].join(' ')} />
        <Typography component="span" noWrap={true} variant="body2">
          {translate.t('laJoiners')}: <b>{datum.data.joiners}</b>
        </Typography>
      </div>
      <div>
        <div className={[classes.box, classes.leaversBox].join(' ')} />
        <Typography component="span" noWrap={true} variant="body2">
          {translate.t('laLeavers')}: <b>{(datum.data.leavers as number) * -1}</b>
        </Typography>
      </div>
      <div>
        <div className={[classes.box, classes.netBox].join(' ')} />
        <Typography component="span" noWrap={true} variant="body2">
          {translate.t('laNetChange')}: <b>{datum.data.netChange}</b>
        </Typography>
      </div>
    </>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(styles)
);

export default enhance(BarTooltip);
