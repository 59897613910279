export type DueDaysAutomation = 'NONE' | 'ON' | 'BEFORE' | 'AFTER';
export type EmployeeAutomation =
  | 'NONE'
  | 'SELECTED_EMP'
  | 'SELECTED_EMP_MANAGER'
  | 'SELECTED_EMP_HR_RESP'
  | 'NAMED_EMP'
  | 'LOGGED_IN_USER'
  | 'LOGGED_IN_USER_MANAGER'
  | 'LOGGED_IN_USER_HR_RESP';
export const getEmployeeAutomationKeys = (includeOtherNamedPerson = false) => {
  const automationKeys = [
    'NONE',
    'SELECTED_EMP',
    'SELECTED_EMP_MANAGER',
    'SELECTED_EMP_HR_RESP',
  ];
  if (includeOtherNamedPerson) {
    automationKeys.push('NAMED_EMP');
  }
  return automationKeys;
};

export type DueDateAutomationSelection =
  | 'NONE'
  | 'AUTO'
  | 'COMPL_DATE'
  | 'MANUAL';
export type EmployeeAutomationSelection =
  | 'NONE'
  | 'AUTO'
  | 'LOGGED_IN_USER'
  | 'LOGGED_IN_USER_MANAGER'
  | 'LOGGED_IN_USER_HR_RESP'
  | 'SELECTED_EMP'
  | 'SELECTED_EMP_MANAGER'
  | 'SELECTED_EMP_HR_RESP';
export interface IAutomationSelection {
  dueDate: DueDateAutomationSelection;
  dueDateAutomationType: DueDaysAutomation;
  dueDateAutomationDays: number;
  assignee: EmployeeAutomationSelection;
  assigneeAutomation: EmployeeAutomation;
  pertainingTo: EmployeeAutomationSelection;
  pertainingToAutomation: EmployeeAutomation;
  copyAttachment: boolean;
}

export interface ICollaborationTemplateTask {
  id: number;
  seq?: number;
  ctId: number;
  title: string;
  description: string;
  type: string;
  language: string;
  dueDateAutomationDays?: number;
  dueDateAutomationType?: DueDaysAutomation;
  assigneeAutomation?: EmployeeAutomation;
  pertainingToAutomation?: EmployeeAutomation;
  otherNamedPerson?: { label: string; value: number };
  created?: {
    time: string;
    user: number;
  };
  updated?: {
    time: string;
    user: number;
  };
  visibleToPertainingTo: boolean;
  attachmentId?: number;
  attachmentName?: string;
  newAttachment?: File;
}

export type ICollaborationTemplateTaskTranslation = {
  title: string;
  description?: string;
  language: string;
};

export interface ICollaborationTemplate {
  id: number;
  title: string;
  description?: string;
  active: boolean;
  language: string;
  type: string;
  tasks?: ICollaborationTemplateTask[];
}

export type ICollaborationTemplateTranslation = {
  title: string;
  description?: string;
  language: string;
};

export interface ICreateTasksFromCollaborationTemplate {
  ctId: number;
  title: string;
  completionDate: Date;
  tasks: ITask[];
}

export interface ITask {
  id: number;
  title: string;
  description?: string;
  language?: string;
  type: string;
  status: string;
  startDate?: Date;
  dueDate?: Date;
  assigneeId?: number;
  pertainingToId?: number;
  visibleToPertainingTo: boolean;
  attachmentId: number;
  attachmentName: string;
  automationSelection?: IAutomationSelection;
}

export type CollaborationTemplateTaskType = {
  fCollaborationTemplateTaskId: number;
  fCollaborationTemplateTaskTemplateFK: number;
  fCollaborationTemplateTaskTitle: string;
  fCollaborationTemplateTaskDescription: string;
  fCollaborationTemplateTaskTypeFK: string;
  fCollaborationTemplateTaskSeq: number;
  fCollaborationTemplateTaskAutomnDueDay: number;
  fCollaborationTemplateTaskAutomnAssignee: string;
  fCollaborationTemplateTaskAutomnPertainingTo: string;
  fCollaborationTemplateTaskVisibleToPertTo: boolean;
  fCollaborationTemplateTaskCreatedTime: string;
  fCollaborationTemplateTaskCreatedUserFK: number;
  fCollaborationTemplateTaskUpdatedTime: string;
  fCollaborationTemplateTaskUpdatedUserFK: number;
  fCollaborationTemplateTaskAttachmentId: number;
  fCollaborationTemplateTaskAttachmentName: string;
  fCollaborationTemplateTaskAttachmentIsArchived: boolean;
};

export type CollaborationTemplateType = {
  fCollaborationTemplateId: number;
  fCollaborationTemplateTitle: string;
  fCollaborationTemplateIsActive: false;
  fCollaborationTemplateDescription: string;
  fCollaborationTemplateCreatedTime: string;
  fCollaborationTemplateCreatedUserFK: number;
  fCollaborationTemplateUpdatedTime: string;
  fCollaborationTemplateUpdatedUserFK: number;
  fCollaborationTemplateTypeFK: string;
  fCollaborationTemplateDefaultLanguage: string;
  fCollaborationTemplateTasks?: CollaborationTemplateTaskType[];
};

export type CollaborationTemplateTranslation = {
  fCollaborationTemplateId: number;
  fCollaborationTemplateTranslationTitle: string;
  fCollaborationTemplateTranslationDescription: string;
  fCollaborationTemplateTranslationLanguage: string;
  fCollaborationTemplateDefaultLanguage: string;
  fCollaborationTemplateCreatedTime: string;
  fCollaborationTemplateUpdatedTime: string;
  fCollaborationTemplateUpdatedUserFK: number;
};

export type CollaborationTaskTranslation = {
  fCollaborationTemplateTaskId: number;
  fCollaborationTemplateTaskTranslationTitle: string;
  fCollaborationTemplateTaskTranslationDescription: string;
  fCollaborationTemplateTaskTranslationLanguage: string;
  fCollaborationTemplateTaskCreatedTime: string;
  fCollaborationTemplateTaskUpdatedTime: string;
  fCollaborationTemplateTaskUpdatedUserFK: number;
};