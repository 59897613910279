import translate from '@/app/utils/translate';
import * as React from 'react';
import Loading from '@/app/components/Loading/Loading';
import JoinersLeaversChart from '@/app/components/Reporting/People/headCountReport/JoinersLeaversChart';
import { LineDatum } from '@nivo/line';
import moment from 'moment/moment';
import { sortByDate } from '@/app/components/Reporting/People/HeadCountReportChart/data';
import EmpCountLineChart from '@/app/components/Reporting/People/headCountReport/EmpCountLineChart';
import { DATE_FORMAT } from '@/app/utils/helper';
import {
  Button,
  Icon,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Theme,
  Toolbar,
  withStyles,
  WithStyles,
  OutlinedInput,
} from '@material-ui/core';
import { compose } from 'recompose';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { HeadcountReportEntryYear } from '@/app/components/Reporting/People/HeadCountReport';
import { HeadCountTableChart } from '@/app/components/Reporting/People/HeadCountReportChart/HeadCountTableChart';

export enum HeadCountReportViewMode {
  MonthlyEmployeeCount = 'monthly_emp_count',
  MonthlyJoinersAndLeavers = 'monthly_joiners_leavers',
  MonthlyTable = 'monthly_table',
}

const styles = (theme: Theme) => ({
  root: {
    minHeight: '250px',
    height: '100%',
  },
  noDataOrScope: {
    padding: theme.spacing.unit * 2,
  },
  navigation: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateAreas: '"previous current next"',
  },
  navigationPrevious: {
    gridArea: 'previous',
    justifySelf: 'start',
  },
  navigationCurrent: {
    gridArea: 'current',
    justifySelf: 'center',
  },
  navigationNext: {
    gridArea: 'next',
    justifySelf: 'end',
  },
  chartDescription: {
    textAlign: 'center' as 'center', // Casting to fix odd TS error
  },
});

type OwnProps = {
  viewMode: HeadCountReportViewMode;
  data: Array<HeadcountReportEntryYear>;
  scopeType?: string;
  loading: boolean;
};
type InnerProps = WithStyles<typeof styles>;
type PropsType = OwnProps & InnerProps;

const enhance = compose<PropsType, OwnProps>(
  withStyles(styles, { withTheme: true }),
);

export const HeadCountReportChart = enhance((props: PropsType) => {
  const { viewMode, loading, data: unsortedData, classes, scopeType } = props;

  const data = useMemo(() => {
    return unsortedData.sort(sortByDate).map(year => {
      return {
        ...year,
        monthly: year.monthly.filter((month: { date: string }) =>
          moment(month.date, 'YYYY-MM-DD').isSameOrBefore(
            moment().endOf('month'),
          ),
        ),
      };
    });
  }, [unsortedData]);

  const [viewDate, setViewDate] = useState<string>();

  useEffect(() => {
    const isViewDateInData = !!data.find(year => year.date === viewDate);

    if (data.length > 0 && (!viewDate || !isViewDateInData)) {
      const years = data.map(year => moment(year.date, 'YYYY-MM-DD'));
      setViewDate(years[years.length - 1].format('YYYY-MM-DD'));
    }
  }, [viewDate, data, setViewDate]);

  const handleSelectYear = useCallback(
    (selectedDate: string) => {
      const ndx = data.findIndex(year => year.date === selectedDate);
      setViewDate(data[ndx].date);
    },
    [data, setViewDate],
  );

  const handlePreviousYear = useCallback(() => {
    const ndx = data.findIndex(year => year.date === viewDate);
    setViewDate(data[ndx - 1].date);
  }, [data, viewDate, setViewDate]);

  const handleNextYear = useCallback(() => {
    const ndx = data.findIndex(year => year.date === viewDate);
    setViewDate(data[ndx + 1].date);
  }, [data, viewDate, setViewDate]);

  if (loading) {
    return <Loading />;
  }

  if (!data || data.length === 0 || !viewDate) {
    return (
      <div className={classes.noDataOrScope}>
        {scopeType === ''
          ? translate.t('laReportNotLoaded')
          : translate.t('laNoData')}
      </div>
    );
  }

  const renderChart = () => {
    switch (viewMode) {
      case HeadCountReportViewMode.MonthlyJoinersAndLeavers:
        return (
          <JoinersLeaversChart
            data={data
              .filter(year => year.date === viewDate)[0]
              .monthly.sort(sortByDate)
              .map((month: LineDatum) => ({
                id: month.date,
                label: moment(month.date, 'YYYY-MM-DD').format('MMM'),
                leavers: -1 * month.leavers,
                joiners: month.joiners,
                netChange: month.joiners - month.leavers,
              }))}
            onBarClick={datum => console.info(datum)}
          />
        );
      case HeadCountReportViewMode.MonthlyEmployeeCount:
        const mecData = [
          {
            id: 'emp_count',
            data: data
              .filter(year => year.date === viewDate)[0]
              .monthly.sort(sortByDate)
              .map((month: LineDatum) => ({
                x: moment(month.date, 'YYYY-MM-DD').format('MMM'),
                y: month.totalCount,
              })),
          },
        ];
        return <EmpCountLineChart data={mecData} />;
      case HeadCountReportViewMode.MonthlyTable:
        const yearData = data
          .filter(year => year.date === viewDate)[0]
          .monthly.sort(sortByDate);

        return <HeadCountTableChart data={yearData} />;
      default:
        return (
          <div className={classes.noDataOrScope}>
            {scopeType === ''
              ? translate.t('laReportNotLoaded')
              : translate.t('laNoData')}
          </div>
        );
    }
  };

  const isMonthlyViewMode = [
    HeadCountReportViewMode.MonthlyEmployeeCount,
    HeadCountReportViewMode.MonthlyJoinersAndLeavers,
    HeadCountReportViewMode.MonthlyTable,
  ].includes(viewMode);
  const isActiveView = {
    first: viewDate === data[0].date,
    last: viewDate === data[data.length - 1].date,
  };

  const renderRange = () => {
    const getViewMonthRange = () =>
      moment(viewDate, 'YYYY-MM-DD')
        .clone()
        .startOf('year')
        .format(DATE_FORMAT) +
      ' - ' +
      moment(viewDate, 'YYYY-MM-DD').format(DATE_FORMAT);

    switch (viewMode) {
      case HeadCountReportViewMode.MonthlyEmployeeCount:
        return translate.t('label_emp_count_in_month', {
          year: getViewMonthRange(),
        });
      case HeadCountReportViewMode.MonthlyJoinersAndLeavers:
        return translate.t('label_emp_joiners_and_leavers_in_month', {
          year: getViewMonthRange(),
        });
      case HeadCountReportViewMode.MonthlyTable:
        return getViewMonthRange();
      default:
        return '';
    }
  };

  return (
    <div className={classes.root}>
      {isMonthlyViewMode && (
        <ListItem>
          <ListItemText className={classes.chartDescription}>
            {renderRange()}
          </ListItemText>
        </ListItem>
      )}
      {renderChart()}
      {isMonthlyViewMode && (
        <Toolbar className={classes.navigation}>
          {!isActiveView.first && (
            <Button
              className={classes.navigationPrevious}
              color="primary"
              disabled={isActiveView.first}
              variant="text"
              onClick={handlePreviousYear}
            >
              <Icon color="inherit">arrow_back</Icon>
              {translate.t('laPrev')}
            </Button>
          )}
          <div className={classes.navigationCurrent}>
            <Select
              value={viewDate}
              onChange={event => handleSelectYear(event.target.value)}
              input={
                <OutlinedInput name="age" labelWidth={0} margin={'dense'} />
              }
            >
              {data.map((year, index) => {
                return (
                  <MenuItem
                    key={`chart-page-${index}`}
                    value={year.date}
                    selected={viewDate === data[index].date}
                  >
                    {moment(year.date).format('YYYY')}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          {!isActiveView.last && (
            <Button
              className={classes.navigationNext}
              color="primary"
              disabled={isActiveView.last}
              variant="text"
              onClick={handleNextYear}
            >
              {translate.t('laNext')}
              <Icon color="inherit">arrow_forward</Icon>
            </Button>
          )}
        </Toolbar>
      )}
    </div>
  );
});
