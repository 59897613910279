import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { WithStyles, Grid, Button } from '@material-ui/core';
import DownArrow from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import { INPUT_TYPE, getUniqValues } from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import changeReviewersFormDialogStyle from './changeReviewersFormDialogStyle';
import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import { getFormValues, reset } from 'redux-form';
import {
  UnitType,
  ListSurvey,
  SurveyChangeReviewers,
  updateEmployeesList,
} from '@/app/redux/surveys';
import { changeFormData, FormDataType } from '@/app/redux/formData';
import {
  ConfirmDialogType,
  openConfirmDialog,
} from '@/app/redux/confirmDialog';
import { getUnitMembers, lookForSubUnits } from '@/old/utils/helper';
import { ReducerState } from '@/app/redux/store';

const FORM_NAME = 'changeReviewersForm';

type MapStateToProps = {
  currentValues: SurveyChangeReviewers;
  unitList: UnitType[];
  unitsEmployeesList: any;
  allActiveEmployees: [];
  audienceCount: number;
};

type MapDispatchToProps = {
  resetForm: () => void;
  changeFormData: (payload: FormDataType) => void;
  setAudienceCount: (units: UnitType[]) => void;
  updateEmployeesList: (allActiveEmployees: any[], employeeList: any[]) => void;
  resetCount: () => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
};

type OwnProps = {
  title: string;
  open: boolean;
  survey: ListSurvey;
  onClose: () => void;
  onSubmit: (survey: SurveyChangeReviewers) => void;
};

type PropsType = OwnProps &
  WithStyles<typeof changeReviewersFormDialogStyle> &
  MapDispatchToProps &
  MapStateToProps;

const emptyForm = () => {
  return {
    id: '',
    audience: [] as UnitType[],
    reviewers: [] as UnitType[],
  };
};

type StateType = {
  formData: SurveyChangeReviewers;
  audience: UnitType[];
};

class ChangeReviewersFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
    audience: undefined,
  };

  componentDidUpdate(prevProps: PropsType) {
    const { open, survey } = this.props;
    const { open: prevOpen, survey: prevSurvey } = prevProps;

    if (
      open &&
      open !== prevOpen &&
      JSON.stringify(survey) !== JSON.stringify(prevSurvey)
    ) {
      this.loadData(survey);
    }
  }

  loadData = (survey: ListSurvey) => {
    const { id, audience, reviewers } = survey;
    const formData = {
      id,
      audience,
      reviewers,
    };

    this.setState({
      formData,
      audience,
    });
  };

  handleChangeReviewers = (event: any) => {
    const { currentValues, allActiveEmployees } = this.props;
    const alreadyAddedReviewersByUnits: any = [];
    let subUnits: number[] = [];
    const reviewers: UnitType[] = [];
    Object.values(event).forEach((item: UnitType) => {
      if (typeof item === 'object') {
        if (item.type === 'UNIT' && item.unitEmployees) {
          alreadyAddedReviewersByUnits.push(...item.unitEmployees);
        }
        if (item.type === 'EMPLOYEE') {
          alreadyAddedReviewersByUnits.push(...[item]);
        }
        reviewers.push(item);
      }
    });

    if (
      !(
        currentValues.reviewers &&
        currentValues.reviewers.length > reviewers.length
      )
    ) {
      subUnits = lookForSubUnits(reviewers);

      if (subUnits && subUnits.length > 0) {
        if (event.hasOwnProperty('preventDefault')) {
          event.preventDefault();
        }

        this.props.openConfirmDialog({
          text: translate.t('confirm_add_subunits'),
          onOk: () => {
            subUnits.forEach((subUnit: any) => {
              alreadyAddedReviewersByUnits.push(
                ...getUnitMembers(subUnit, true),
              );
            });
            this.props.updateEmployeesList(
              allActiveEmployees,
              alreadyAddedReviewersByUnits,
            );
            this.setDataField(reviewers, subUnits);
          },
          onClose: () => this.setFormReviewers(reviewers),
          customOk: translate.t('laYes'),
          customClose: translate.t('laNo'),
        });
      }
    }
    this.props.updateEmployeesList(
      allActiveEmployees,
      alreadyAddedReviewersByUnits,
    );
  };

  getSubUnitsValuesFromIds = (ids: number[]) => {
    const { unitList } = this.props;

    const values = ids.map((id: number) =>
      unitList.find((unit: UnitType) => parseInt(unit.value, 10) === id),
    );

    return values;
  };

  setDataField = (unitList: UnitType[], subUnits: number[]) => {
    const subUnitValues = this.getSubUnitsValuesFromIds(subUnits);
    const newUnitList = [...unitList].concat(subUnitValues);
    const units: UnitType[] = getUniqValues(newUnitList);

    this.setFormReviewers(units);
  };

  setFormReviewers = (unitList: UnitType[]) => {
    const { currentValues } = this.props;
    const reviewers = getUniqValues(unitList);

    this.setState({
      formData: { ...currentValues, reviewers },
    });

    this.props.changeFormData({
      form: FORM_NAME,
      field: 'reviewers',
      value: reviewers,
    });
  };

  setEmptyState = () => {
    this.setState({
      formData: emptyForm(),
      audience: undefined,
    });
  };

  handleClose = () => {
    const { onClose, resetForm } = this.props;

    this.setEmptyState();
    onClose();
    resetForm();
  };

  handleSubmit = (values: SurveyChangeReviewers) => {
    const { onSubmit, resetForm } = this.props;

    this.setEmptyState();
    onSubmit(values);
    resetForm();
  };

  copyAudienceToReviewers = () => {
    this.setFormReviewers(this.state.audience);
  };

  renderAudience = () => (
    <Grid item xs={12}>
      <FormFields
        inputs={[
          {
            type: INPUT_TYPE.MULTISELECT,
            code: 'audience',
            name: 'audience',
            label: translate.t('label_audience'),
            childrenInputs: this.props.unitList.concat(
              this.props.unitsEmployeesList,
            ),
            order: 1,
            required: false,
            configs: {
              isSearchable: false,
              isDisabled: true,
              isCreatable: false,
            },
          },
        ]}
      />
    </Grid>
  );

  renderReviewers = () => {
    const { survey, unitList, allActiveEmployees } = this.props;
    let addedUnits: any[] = [];
    let addedEmployees: any[] = [];
    if (survey) {
      survey.reviewers.forEach(entry => {
        if (entry.type === 'UNIT') {
          addedUnits.push(entry);
        }
        if (entry.type === 'EMPLOYEE') {
          addedEmployees.push(entry);
        }
      });
    }
    let addedUnitsIds = addedUnits.map(u => u.value);
    let addedEmployeesIds = addedEmployees.map(e => e.value);
    let remainingUnits = unitList.filter(u => !addedUnitsIds.includes(u.value));
    let remainingEmployees = allActiveEmployees.filter(
      (e: any) => !addedEmployeesIds.includes(e.value),
    );
    const completeReviewersList = remainingUnits.concat(remainingEmployees);

    return (
      <Grid item xs={12}>
        <FormFields
          inputs={[
            {
              type: INPUT_TYPE.MULTISELECT,
              code: 'reviewers',
              name: 'reviewers',
              label: translate.t('label_reviewers'),
              childrenInputs:
                this.state.formData.reviewers.length ===
                addedUnits.length + addedEmployees.length
                  ? completeReviewersList
                  : remainingUnits.concat(this.props.unitsEmployeesList),
              order: 2,
              required: false,
              configs: {
                isSearchable: true,
                isDisabled: false,
                isCreatable: false,
              },
              onChange: (value: any) => this.handleChangeReviewers(value),
            },
          ]}
        />
      </Grid>
    );
  };

  FormDialog = getFormDialog(FORM_NAME);

  render() {
    const { title, open, classes } = this.props;
    const { formData } = this.state;
    const { FormDialog } = this;

    return (
      <FormDialog
        title={title}
        open={open}
        onClose={this.handleClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={this.handleSubmit}
        customSubmitLabel={translate.t('label_change')}
        customCloseLabel={translate.t('laCancel')}
      >
        <Grid container className={classes.root} justify="space-between">
          <Grid container item xs={12} className={classes.multiSelectContainer}>
            {this.renderAudience()}
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            alignItems="center"
            spacing={8}
          >
            <div className={classes.card} />

            <Button
              variant="contained"
              size="small"
              className={classes.button}
              onClick={this.copyAudienceToReviewers}
            >
              <DownArrow />
              {translate.t('laCopy')}
            </Button>
          </Grid>

          <Grid container item xs={12} className={classes.multiSelectContainer}>
            {this.renderReviewers()}
          </Grid>
        </Grid>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { unitList, unitsEmployeesList, allActiveEmployees } = state.surveys;

  return {
    currentValues: getFormValues(FORM_NAME)(state),
    unitList,
    unitsEmployeesList,
    allActiveEmployees,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetForm: () => dispatch(reset(FORM_NAME)),
  updateEmployeesList: (allActiveEmployees: any[], employeeList: any[]) =>
    dispatch(updateEmployeesList(allActiveEmployees, employeeList)),
  changeFormData: (payload: FormDataType) => changeFormData(dispatch, payload),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(changeReviewersFormDialogStyle, { withTheme: true }),
);

export default enhance(ChangeReviewersFormDialog);
