import React from 'react';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Paper, Select, TextField, WithStyles, withStyles } from '@material-ui/core';
import templateTranslationDialogStyles from './templateTranslationDialogStyles';
import { compose } from 'recompose';
import { getCurrentLanguage } from '@/app/utils/helper';
import { getCompanyLanguages } from '@/old/utils/helper';
import translate from '@/app/utils/translate';
import { CollaborationTemplateTranslation, ICollaborationTemplate, ICollaborationTemplateTranslation } from '../types';
import * as yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { useSnackbar } from 'notistack';

interface IncomingProps {
  open: boolean;
  disabledSave: boolean;
  template: ICollaborationTemplate;
  onSubmit: (lang: string) => void;
  onCancel: () => void;
  onClose: () => void;
  onSave: (ctId: number, values: ICollaborationTemplateTranslation) => void;
}

type PropsType = IncomingProps & WithStyles<typeof templateTranslationDialogStyles>;

type TranslationForm = {
  title: string;
  description?: string;
  language: string;
};

export const CollaborationTemplateTranslationDialog = (props: PropsType) => {
  const { open, classes, template, onSave, onClose } = props;

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    title: '',
    description: '',
    language: getCurrentLanguage(),
  };

  const validationSchema = yup.object<TranslationForm>().shape({
    title: yup.string(),
    description: yup.string(),
    language: yup.string().required(),
  });

  const handleSave = React.useCallback(
    async (values: TranslationForm, actions: FormikProps<TranslationForm>) => {
      await onSave(template.id, values);
      actions.resetForm();
      onClose();
    },
    [template]
  );

  const renderDialog = ({
    errors,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    values,
    setFieldValue,
  }: FormikProps<TranslationForm>) => {
    const loadTranslations = (language: string) => {
      if (!!template) {
        Service.get(
          API.collaborationTemplates.getTemplateTranslation(template.id, language),
          (res: CollaborationTemplateTranslation) => {
            setFieldValue('title', !!res ? res.fCollaborationTemplateTranslationTitle : '');
            setFieldValue('description', !!res ? res.fCollaborationTemplateTranslationDescription : '');
          },
          () => {
            enqueueSnackbar(translate.t('error_template_task_translation'), {
              variant: 'error',
            });
          },
        );
      }
    };

    // Initial load
    React.useEffect(() => {
      loadTranslations(values.language);
    }, []);

    // Load onChange
    React.useEffect(() => {
      loadTranslations(values.language);
    }, [values.language, template]);

    const handleCancel = () => {
      resetForm();
      onClose();
    };

    const handleSubmitClick = () => {
      handleSubmit();
    };

    const submitContent = !isSubmitting ? translate.t('laSave') : (
      <CircularProgress size={22} />
    );

    return (
      <Modal
        open={open}
      >
        <Paper className={classes.root}>
          <Form >
            <h3>{translate.t('translate_template')}</h3>

            <FormControl
              fullWidth
              margin="normal"
            >
              <InputLabel htmlFor="type-input" shrink={true}>{translate.t('laLanguage')}</InputLabel>
              <Field
                autoFocus
                id="language"
                type="select"
                component={Select}
                name="language"
                error={Boolean(errors.language && touched.language)}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.language}
                inputProps={{ name: 'language', id: 'type-input' }}
              >
                {getCompanyLanguages().map(
                  ({ fComLanguageCode, fComLanguageName }: any) => {
                    return (
                      <MenuItem key={fComLanguageCode} value={fComLanguageCode}>
                        {fComLanguageName}
                      </MenuItem>
                    );
                  },
                )}
              </Field>
            </FormControl>
            <Field
              id="title"
              component={TextField}
              error={Boolean(errors.title && touched.title)}
              fullWidth
              helperText={(errors.title && touched.title) && errors.title}
              label={translate.t('laTitle')}
              margin="normal"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
            />
            <Field
              id="description"
              component={TextField}
              error={Boolean(errors.description && touched.description)}
              fullWidth
              helperText={(errors.description && touched.description) && errors.description}
              label={translate.t('laDescription')}
              margin="normal"
              name="description"
              multiline={true}
              rows={1}
              rowsMax={5}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
            />
            <div className={classes.buttonsWrapper}>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={handleCancel}
              >
                {translate.t('laCancel')}
              </Button>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid || isSubmitting}
                onClick={handleSubmitClick}
              >
                {submitContent}
              </Button>
            </div>
          </Form>
        </Paper>
      </Modal>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {renderDialog}
    </Formik>
  );
};

const enhance = compose<any, any>(withStyles(templateTranslationDialogStyles));

export default enhance(CollaborationTemplateTranslationDialog);