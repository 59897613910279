import {
  Chip,
  createMuiTheme,
  Icon,
  IconButton,
  MuiThemeProvider,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import styles from '@/app/components/Worklist/worklists/styles';
import { ICollaborationTemplate } from '@/app/components/Worklist/types';
import translate from '@/app/utils/translate';
import { red } from '@material-ui/core/colors';
import SortIcon from '@material-ui/icons/Sort';
import { Enum } from '@/old/utils/helper';

const InactiveChip = (props: any) => {
  const inactiveTheme = createMuiTheme({
    palette: {
      primary: {
        main: red[600],
      },
    },
    typography: { useNextVariants: true },
  });

  return (
    <MuiThemeProvider theme={inactiveTheme}>
      <Chip {...props} />
    </MuiThemeProvider>
  );
};

type InnerProps = WithStyles<typeof styles>;
type OwnProps = {
  collaborationTemplate: ICollaborationTemplate;
  isExpanded: boolean;
  setAddTaskCollaborationTemplateId: (id: number | undefined) => void;
  setOpenSortDialog: (open: boolean) => void;
  menuComponent?: React.ReactNode;
  taskTypes: Enum[];
};
type Props = OwnProps & InnerProps;

const enhance = compose<Props, OwnProps>(
  withStyles(styles, {
    withTheme: true,
  }),
);

export const WorklistPanelSummary = enhance((props: Props) => {
  const {
    classes,
    collaborationTemplate,
    isExpanded,
    setAddTaskCollaborationTemplateId,
    setOpenSortDialog,
    menuComponent,
    taskTypes,
  } = props;

  const handleAddTaskOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setAddTaskCollaborationTemplateId(collaborationTemplate.id);
  };

  const handleSortDialogOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpenSortDialog(true);
  };

  const resolveType = (type: string): string => {
    const foundType = taskTypes.find((t) => t.code === type);

    return !!foundType ? foundType.name : type;
  };

  return (
    <>
      <div className={classes.expansionPanelSummaryContent}>
        <Typography
          component="span"
          className={classes.collaborationTemplateHeading}
        >
          {collaborationTemplate.title}
        </Typography>
        {!collaborationTemplate.active && (
          <InactiveChip
            className={classes.inactiveChip}
            label={translate.t('label_inactive')}
            color="secondary"
          />
        )}
        {menuComponent}

        <Typography component="span" className={classes.typeLabel}>
          {`${translate.t('table_col_type')}: ${resolveType(collaborationTemplate.type)}`}
        </Typography>
      </div>
      <div className={classes.expansionPanelSummaryContent}>
        {isExpanded && (
          <>
            <Tooltip title={translate.t('laSort')}>
              <IconButton
                color="primary"
                component="span"
                onClick={handleSortDialogOpen}
              >
                <SortIcon />
              </IconButton>
            </Tooltip>
            <IconButton color="primary" onClick={handleAddTaskOpen}>
              <Icon>add_circle_outlined</Icon>
            </IconButton>
          </>
        )}
      </div>
    </>
  );
});
