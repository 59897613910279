// import { StylesConfig } from 'react-select/src/styles';

// const customStyles: StylesConfig = {
const customStyles: any = {
  input: (provided: any) => ({
    ...provided,
    marginLeft: 0,
  }),
  multiValueLabel: (provided: any, state: any) => {
    return state.data.isFixed ? {
      ...provided,
      paddingRight: 6 
    } : provided;
  },
  multiValueRemove: (provided: any, state: any) => {
    return state.data.isFixed ? { 
      ...provided,
      display: 'none'
    } : provided;
  },
  placeholder: (provided: any) => ({
    ...provided,
    marginLeft: 0,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    margin: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 12000 }),
  menu: (provided: any) => ({ ...provided, zIndex: 12000 })
};

export default customStyles;
