import * as React from 'react';
import {
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  Icon,
  IconButton,
  Paper,
  Typography,
  withStyles,
  WithStyles,
  ExpansionPanelSummary,
} from '@material-ui/core';

import { compose } from 'recompose';

import API from '@/app/api/internalAPIs';
import ConfirmDialog from '@/app/components/ConfirmDialog/ConfirmDialog';
import { getFormData } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';

import {
  mapCollaborationTemplateForBE,
  mapCollaborationTemplatesForUI,
  mapCollaborationTemplateForUI,
  mapCollaborationTemplateTaskForBE,
  mapCollaborationTemplateTaskForUI,
  mapCollaborationTemplateTranslationToBE,
  mapTaskTranslationToBE,
} from './service';
import {
  DueDaysAutomation,
  ICollaborationTemplate,
  ICollaborationTemplateTask,
  EmployeeAutomation,
  ICollaborationTemplateTaskTranslation,
  CollaborationTemplateType,
  CollaborationTemplateTaskType,
  ICollaborationTemplateTranslation,
} from './types';
import styles from './worklists/styles';
import WorklistEditDialog from './worklists/WorklistEditDialog';
import WorklistMenu from './worklists/WorklistMenu';
import TaskTable from './worklists/TaskTable';
import TaskEditDialog from './worklists/TaskEditDialog';

import SortableTaskDialog from './worklists/SortableTaskDialog';
import { getCompanyLanguages, getEnums } from '@/old/utils/helper';
import ConfigureUiLabelButton from '../ConfigurableLabels/ConfigureUiLabelButton/ConfigureUiLabelButton';
import { fetchAllEnums } from '@/app/redux/enums';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CollaborationTemplateTranslationDialog from './worklists/CollaborationTemplateTranslationDialog';
import { WorklistPanelSummary } from '@/app/components/Worklist/WorklistPanelSummary';

const emptyCollaborationTemplate = {
  title: '',
  description: '',
  language: '',
  type: '',
};

const emptyCollaborationTemplateTask = {
  collaborationTemplateId: 0,
  title: '',
  description: '',
  type: '',
  dueDateAutomationDays: 0,
  dueDateAutomationType: 'NONE' as DueDaysAutomation,
  assigneeAutomation: 'NONE' as EmployeeAutomation,
  pertainingToAutomation: 'SELECTED_EMP' as EmployeeAutomation,
  visibleToPertainingTo: true,
};

const sortByStringProp = (prop: string, a: any, b: any) => {
  return a[prop].localeCompare(b[prop]);
};

const sortByName = (a: any, b: any) => {
  return sortByStringProp('name', a, b);
};

const sortByTitle = (a: ICollaborationTemplate, b: ICollaborationTemplate) => {
  return sortByStringProp('title', a, b);
};

const getTaskTypes = () => {
  return getEnums('COLLAB_TASK_TYPE').filter((item: any) => item.isActive);
};

type MapDispatchToProps = {
  fetchAllEnums: () => void;
};

type InnerProps = WithStyles<typeof styles>;
type Props = MapDispatchToProps & InnerProps;

const Worklists = (props: Props) => {
  const {
    classes,
    fetchAllEnums: handleUpdateEnum,
  } = props;
  const taskTypes = getTaskTypes();

  const { enqueueSnackbar } = useSnackbar();
  const compLangs = getCompanyLanguages();

  const defaultLanguage = getCompanyLanguages().find((lang) => lang.fComLanguageIsDefault);

  const [collaborationTemplates, setCollaborationTemplates] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [translationDialogOpen, setTranslationDialogOpen] = React.useState(false);

  const loadAllTemplates = React.useCallback(() => {
    return Service.get(
      API.collaborationTemplates.all(''),
      (res: CollaborationTemplateType[]) => {
        setCollaborationTemplates(mapCollaborationTemplatesForUI(res));
        setLoading(false);
        setError('');
      },
      (err: any) => {
        setCollaborationTemplates([]);
        setLoading(false);
        setError(err);
      },
    );
  }, []);

  React.useEffect(() => {
    loadAllTemplates();
  }, []);

  const [expandedCollaborationTemplateId, setExpandedCollaborationTemplateId] = React.useState<any>();
  const [collaborationTemplateDetails, setCollaborationTemplateDetails] = React.useState<ICollaborationTemplate>();
  const [collaborationTemplateDetailsLoading, setCollaborationTemplateDetailsLoading] = React.useState(
    false,
  );
  const [collaborationTemplateDetailsError, setCollaborationTemplateDetailsError] = React.useState<any>();
  const loadCollaborationTemplateDetails = React.useCallback((id: number) => {
    setCollaborationTemplateDetailsLoading(true);
    setCollaborationTemplateDetailsError(undefined);
    setExpandedCollaborationTemplateId(id);
    Service.get(
      API.collaborationTemplates.get(id, ''),
      (res: CollaborationTemplateType) => {
        setCollaborationTemplateDetails(mapCollaborationTemplateForUI(res));
        setCollaborationTemplateDetailsLoading(false);
      },
      (err: any) => {
        setCollaborationTemplateDetailsError(err);
        setCollaborationTemplateDetailsLoading(false);
      },
    );
  }, []);

  const updateTask = React.useCallback(
    async (values: ICollaborationTemplateTask) => {
      const { formData } = getFormData(mapCollaborationTemplateTaskForBE(values));
      await Service.putFormData(
        API.collaborationTemplates.updateTask(values.ctId, values.id),
        formData,
        (res: CollaborationTemplateTaskType) => {
          const updatedTask = mapCollaborationTemplateTaskForUI(res);
          setCollaborationTemplateDetails((prevDetails: any) => {
            const updatedDetails = Object.assign({}, prevDetails);
            updatedDetails.tasks = prevDetails.tasks.map((item: any) => {
              if (item.id === values.id) {
                return updatedTask;
              }
              return item;
            });
            return updatedDetails;
          });
          enqueueSnackbar(translate.t('text_item_updated'), {
            variant: 'success',
          });
        },
        (err: any) => {
          console.error('PUT ERROR', err);
        },
      );
    },
    [setCollaborationTemplateDetails],
  );
  const addTask = React.useCallback(
    async (values: ICollaborationTemplateTask) => {
      const { formData } = getFormData(mapCollaborationTemplateTaskForBE(values));
      await Service.postFormData(
        API.collaborationTemplates.addTask(values.ctId),
        formData,
        (res: CollaborationTemplateTaskType) => {
          const addedTask = mapCollaborationTemplateTaskForUI(res);
          setCollaborationTemplateDetails((prevDetails: ICollaborationTemplate) => {
            const updatedDetails = prevDetails;
            updatedDetails.tasks.push(addedTask);
            return updatedDetails;
          });
          enqueueSnackbar(translate.t('text_item_added'), {
            variant: 'success',
          });
        },
        (err: any) => {
          console.error('POST ERROR', err);
        },
      );
    },
    [setCollaborationTemplateDetails],
  );

  const [collaborationTemplateToDelete, setCollaborationTemplateToDelete] = React.useState<any>();
  const [collaborationTemplateDialogOpen, setCollaborationTemplateDialogOpen] = React.useState(false);
  const [addTaskCollaborationTemplateId, setAddTaskCollaborationTemplateId] = React.useState<any>();
  const [openSortDialog, setOpenSortDialog] = React.useState(false);

  const [collaborationTemplateMenuAnchorEl, setCollaborationTemplateMenuAnchorEl] = React.useState<any>();
  const handleCollaborationTemplateMenuClose = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setCollaborationTemplateMenuAnchorEl(undefined);
  };

  const handleTranslationDialogClose = () => {
    setTranslationDialogOpen(false);
  };

  const handleStartCollaborationTemplateAdd = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setExpandedCollaborationTemplateId(undefined);
    setCollaborationTemplateDetails(undefined);
    setCollaborationTemplateDialogOpen(true);
  };

  const handleCollaborationTemplateDialogClose = () => setCollaborationTemplateDialogOpen(false);

  const handleCollaborationTemplateSave = (values: ICollaborationTemplate) => {
    // Add new, if id was not set
    if (!values.id) {
      Service.post(
        API.collaborationTemplates.add,
        mapCollaborationTemplateForBE(values),
        (res: CollaborationTemplateType) => {
          const addedCollaborationTemplate = mapCollaborationTemplateForUI(res);
          setCollaborationTemplates(prevCollaborationTemplates => {
            const updatedCollaborationTemplates = prevCollaborationTemplates;
            updatedCollaborationTemplates.push(addedCollaborationTemplate);
            return updatedCollaborationTemplates;
          });
          setExpandedCollaborationTemplateId(addedCollaborationTemplate.id);
          loadCollaborationTemplateDetails(addedCollaborationTemplate.id);
          enqueueSnackbar(translate.t('text_item_added'), {
            variant: 'success',
          });
        },
        (err: any) => {
          console.error('POST ERROR', err);
        },
      );

      // Update existing, if id was set
    } else {
      Service.put(
        API.collaborationTemplates.update(values.id),
        mapCollaborationTemplateForBE(values),
        (res: CollaborationTemplateType) => {
          setCollaborationTemplates(prevCollaborationTemplates => {
            const updatedCollaborationTemplate = mapCollaborationTemplateForUI(res);
            const ctDetails = Object.assign({}, collaborationTemplateDetails);
            ctDetails.title = updatedCollaborationTemplate.title;
            ctDetails.description = updatedCollaborationTemplate.description;
            ctDetails.language = updatedCollaborationTemplate.language;
            ctDetails.type = updatedCollaborationTemplate.type;
            setCollaborationTemplateDetails(ctDetails);
            return prevCollaborationTemplates.map(prevCollaborationTemplate => {
              if (prevCollaborationTemplate.id === values.id) {
                return updatedCollaborationTemplate;
              }
              return prevCollaborationTemplate;
            });
          });
          enqueueSnackbar(translate.t('text_item_updated'), {
            variant: 'success',
          });
        },
        (err: any) => {
          console.error('PUT ERROR', err);
        },
      );
    }
  };

  const handleTemplateTasksSort = (templateId: number, values: ICollaborationTemplateTask[]) => {
    Service.put(
      API.collaborationTemplates.sortTasks(templateId),
      values.map(value => mapCollaborationTemplateTaskForBE(value)),
      (res: CollaborationTemplateTaskType[]) => res.map((data: CollaborationTemplateTaskType) => mapCollaborationTemplateTaskForUI(data)),
      (err: Error) => { throw err; }
    )
      .then((data) => {
        setCollaborationTemplateDetails((prevDetails: ICollaborationTemplate) => {
          const updatedDetails = prevDetails;
          updatedDetails.tasks = data;
          return updatedDetails;
        });
        setOpenSortDialog(false);
        enqueueSnackbar(translate.t('text_item_updated'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(translate.t('error_template_tasks_sort'), {
          variant: 'error',
        });
      });
  };

  const handleTemplateTranslation = (ctId: number, values: ICollaborationTemplateTranslation) => {
    Service.put(
      API.collaborationTemplates.translateTemplate(ctId),
      mapCollaborationTemplateTranslationToBE(values),
      (res: any) => res,
      (err: Error) => { throw err; }
    )
      .then((_data) => {
        loadAllTemplates();
        enqueueSnackbar(translate.t('text_item_updated'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(translate.t('error_template_task_translate'), {
          variant: 'error',
        });
      });
  };

  const handleTemplateTaskTranslation = (templateId: number, taskId: number, values: ICollaborationTemplateTaskTranslation) => {
    Service.put(
      API.collaborationTemplates.translateTask(templateId, taskId),
      mapTaskTranslationToBE(values),
      (res: any) => res,
      (err: Error) => { throw err; }
    )
      .then((_data) => {
        loadCollaborationTemplateDetails(templateId);
        enqueueSnackbar(translate.t('text_item_updated'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(translate.t('error_template_task_translate'), {
          variant: 'error',
        });
      });
  };

  const renderCollaborationTemplates = () => {
    if (!collaborationTemplates || collaborationTemplates.length === 0) {
      return <Paper elevation={0}>{translate.t('text_no_worklists')}</Paper>;
    }

    return collaborationTemplates.sort(sortByTitle).map((collaborationTemplate: ICollaborationTemplate) => {

      const isCollaborationTemplateExpanded = collaborationTemplate.id === expandedCollaborationTemplateId;

      const toggleExpanded = () => {
        if (isCollaborationTemplateExpanded) {
          setExpandedCollaborationTemplateId(undefined);
        } else {
          loadCollaborationTemplateDetails(collaborationTemplate.id);
        }
      };

      const handleStartCollaborationTemplateEdit = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setCollaborationTemplateMenuAnchorEl(undefined);
        setCollaborationTemplateDialogOpen(true);
      };

      const handleCollaborationTemplateMenuOpen = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setCollaborationTemplateMenuAnchorEl(e.target);
      };

      const handleCollaborationTemplateActivate = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        const updatedCollaborationTemplate = Object.assign({}, collaborationTemplate);
        updatedCollaborationTemplate.active = true;
        Service.put(
          API.collaborationTemplates.update(collaborationTemplate.id),
          {
            ...mapCollaborationTemplateForBE(updatedCollaborationTemplate),
          },
          (res: CollaborationTemplateType) => {
            setCollaborationTemplates(prevCollaborationTemplates => {
              return prevCollaborationTemplates.map(prevCollaborationTemplate => {
                if (collaborationTemplate.id === prevCollaborationTemplate.id) {
                  return mapCollaborationTemplateForUI(res);
                }
                return prevCollaborationTemplate;
              });
            });
            setCollaborationTemplateMenuAnchorEl(undefined);
          },
          (err: any) => {
            console.error('UPDATE ERROR', err);
          },
        );
      };

      const handleCollaborationTemplateDeactivate = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        const updatedCollaborationTemplate = Object.assign({}, collaborationTemplate);
        updatedCollaborationTemplate.active = false;
        Service.put(
          API.collaborationTemplates.update(collaborationTemplate.id),
          {
            ...mapCollaborationTemplateForBE(updatedCollaborationTemplate),
          },
          (res: CollaborationTemplateType) => {
            setCollaborationTemplates(prevCollaborationTemplates => {
              return prevCollaborationTemplates.map(prevCollaborationTemplate => {
                if (collaborationTemplate.id === prevCollaborationTemplate.id) {
                  return mapCollaborationTemplateForUI(res);
                }
                return prevCollaborationTemplate;
              });
            });
            setCollaborationTemplateMenuAnchorEl(undefined);
          },
          (err: any) => {
            console.error('UPDATE ERROR', err);
          },
        );
      };

      const handleStartCollaborationTemplateDelete = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setCollaborationTemplateToDelete(collaborationTemplate);
      };

      const handleCancelCollaborationTemplateDelete = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setCollaborationTemplateMenuAnchorEl(undefined);
        setCollaborationTemplateToDelete(undefined);
      };

      const handleCollaborationTemplateDelete = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        Service.delete(
          API.collaborationTemplates.delete,
          {
            fCollaborationTemplateIds: [collaborationTemplate.id],
          },
          (_res: any) => {
            setCollaborationTemplates(prevCollaborationTemplates =>
              prevCollaborationTemplates.filter(a => a.id !== collaborationTemplate.id),
            );
            setCollaborationTemplateToDelete(undefined);
            setExpandedCollaborationTemplateId(undefined);
            setCollaborationTemplateDetails(undefined);
            enqueueSnackbar(translate.t('text_item_deleted'), {
              variant: 'success',
            });
          },
          (err: any) => {
            console.error('DELETE ERROR', err);
          },
        );
      };

      const handleTaskDelete = (id: number) => {
        Service.delete(
          API.collaborationTemplates.deleteTask(collaborationTemplate.id),
          { fCollaborationTemplateIds: [id] },
          (_res: any) => {
            setCollaborationTemplateDetails((prevWl: ICollaborationTemplate) => {
              const updatedTasks = prevWl.tasks.filter(
                (task: ICollaborationTemplateTask) => task.id !== id,
              );
              return {
                ...prevWl,
                tasks: updatedTasks,
              };
            });
            enqueueSnackbar(translate.t('text_item_deleted'), {
              variant: 'success',
            });
          },
          (err: any) => {
            console.error('DELETE ERROR', err);
          },
        );
      };

      const handleTaskAdd = async (values: ICollaborationTemplateTask) =>
        await addTask(values);
      const handleTaskUpdate = async (values: ICollaborationTemplateTask) =>
        await updateTask(values);

      const deleteCollaborationTemplateConfirmDialog = !!collaborationTemplateToDelete && (
        <ConfirmDialog
          open={Boolean(collaborationTemplateToDelete)}
          text={translate.t('text_confirm_worklist_delete', {
            title: collaborationTemplate.title,
          })}
          onOk={handleCollaborationTemplateDelete}
          onClose={handleCancelCollaborationTemplateDelete}
        />
      );

      const addTaskDialog =
        !(!addTaskCollaborationTemplateId || !isCollaborationTemplateExpanded) && (
          <TaskEditDialog
            open={true}
            initialValues={{
              ...emptyCollaborationTemplateTask,
              ctId: collaborationTemplate.id,
              type: collaborationTemplate.type || '',
              language: collaborationTemplate.language || '',
              otherNamedPerson: { label: '', value: 0 }
            }}
            taskTypes={taskTypes.sort(sortByName)}
            onSave={handleTaskAdd}
            onClose={() => setAddTaskCollaborationTemplateId(undefined)}
            hidePertainingTo={true}
          />
        );

      return (
        <ExpansionPanel
          key={collaborationTemplate.id}
          expanded={isCollaborationTemplateExpanded}
          onChange={toggleExpanded}
        >

          <ExpansionPanelSummary
            classes={{ content: classes.expansionPanelSummary }}
            expandIcon={<Icon>expand_more</Icon>}
          >
            <WorklistPanelSummary
              collaborationTemplate={collaborationTemplate}
              taskTypes={taskTypes}
              isExpanded={isCollaborationTemplateExpanded}
              setAddTaskCollaborationTemplateId={setAddTaskCollaborationTemplateId}
              setOpenSortDialog={setOpenSortDialog}
              menuComponent={isCollaborationTemplateExpanded ? (<>
                {deleteCollaborationTemplateConfirmDialog}
                <div className={classes.collaborationTemplateMenuButtonContainer}>
                  <WorklistMenu
                    anchorEl={collaborationTemplateMenuAnchorEl}
                    isTemplateActive={collaborationTemplate.active}
                    templateHasTasks={collaborationTemplateDetails &&
                      collaborationTemplateDetails.tasks &&
                      collaborationTemplateDetails.tasks.length > 0}
                    onActivate={handleCollaborationTemplateActivate}
                    onDeactivate={handleCollaborationTemplateDeactivate}
                    onRename={handleStartCollaborationTemplateEdit}
                    onDelete={handleStartCollaborationTemplateDelete}
                    onClose={handleCollaborationTemplateMenuClose}
                    setTranslationDialogOpen={setTranslationDialogOpen}
                    setCollaborationTemplateMenuAnchorEl={setCollaborationTemplateMenuAnchorEl}
                  />
                  <IconButton color="primary" onClick={handleCollaborationTemplateMenuOpen}>
                    <Icon>more_vert</Icon>
                  </IconButton>
                </div>
              </>) : (<div className={classes.collaborationTemplateMenuButtonContainer} />)}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {!!collaborationTemplateDetailsLoading && (
              <CircularProgress size={24} className={classes.loading} />
            )}
            {!!collaborationTemplateDetailsError && (
              <div>{`ERROR loading details: ${collaborationTemplateDetailsError}`}</div>
            )}
            {!(!collaborationTemplateDetails ||
            !!collaborationTemplateDetailsLoading ||
            !!collaborationTemplateDetailsError) && (
              <>
                {addTaskDialog}
                <Paper elevation={0} className={classes.collaborationTemplateDesc}>
                  <Typography variant="caption">
                    {collaborationTemplate.description}
                  </Typography>
                </Paper>
                <div className={classes.flexBreak} />
                <TaskTable
                  tasks={collaborationTemplateDetails.tasks}
                  onDelete={handleTaskDelete}
                  onUpdate={handleTaskUpdate}
                  onTranslate={handleTemplateTaskTranslation}
                />
              </>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  if (loading) {
    return <CircularProgress size={24} className={classes.loading} />;
  }

  if (error) {
    return <div color="error">ERROR: {error}</div>;
  }

  return (
    <div className={classes.root}>
      <WorklistEditDialog
        open={collaborationTemplateDialogOpen}
        taskTypes={taskTypes}
        languages={compLangs}
        defaultLanguage={defaultLanguage}
        initialValues={collaborationTemplateDetails ||
          {
            ...emptyCollaborationTemplate,
            language: defaultLanguage.fComLanguageCode,
            type: taskTypes[0].code || '',
          }
        }
        onSave={handleCollaborationTemplateSave}
        onClose={handleCollaborationTemplateDialogClose}
      />
        <SortableTaskDialog
          open={openSortDialog}
          isDisabledSave={false}
          title={translate.t('laSort')}
          onClose={() => { setOpenSortDialog(false); }}
          templateDetails={collaborationTemplateDetails || {} as ICollaborationTemplate}
          onSave={handleTemplateTasksSort}
        />
      <Typography variant="h6" className={classes.sectionTitle}>
        <ConfigureUiLabelButton
          term={'laChecklistsAndTemplates'}
          fetchAllEnums={handleUpdateEnum}
        />
      </Typography>
      <Button color="primary" onClick={handleStartCollaborationTemplateAdd}>
        <Icon>add_circle_outlined</Icon>&nbsp;
        {translate.t('laCreateNewTemplate')}
      </Button>
      <CollaborationTemplateTranslationDialog
        open={translationDialogOpen}
        onCancel={handleTranslationDialogClose}
        template={collaborationTemplateDetails}
        onSave={handleTemplateTranslation}
        onClose={() => setTranslationDialogOpen(false)}
      />
      <div className={classes.collaborationTemplate}>{renderCollaborationTemplates()}</div>
    </div>
  );
};

const WorklistsWrapper = (props: Props) => {
  return (
    <SnackbarProvider>
      <Worklists {...props} />
    </SnackbarProvider>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllEnums: () => dispatch<any>(fetchAllEnums()),
});

const enhance = compose<any, any>(
  connect(null, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
);

export default enhance(WorklistsWrapper);
