import * as React from 'react';
import {
  Theme,
  WithStyles,
  withStyles,
  Typography,
  Grid,
  Popover,
} from '@material-ui/core';
import { compose } from 'recompose';
import moment from 'moment';

import { ReducerState } from '@/app/redux/store';
import { fetchMyManagedUnits, fetchMyHrUnits } from '@/app/redux/currentUser';

import { IReportConfig } from '../ReportConfig';
import translate from '@/app/utils/translate';
import ToolbarFilterButton from '../components/ToolbarFilterButton';
import ScopeSelector from '../components/ScopeSelector';
import { hasRole } from '@/app/utils/security';
import { ScopeType, IReportSettings } from '../ReportSettings';
import ToolbarFilterButtonGroup from '../components/ToolbarFilterButtonGroup';
import {
  currentUserHasTeam,
  eventTargetAsHtmlElement,
} from '@/old/utils/helper';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FetchPrimaryUnitsHOC from '@/app/hocs/FetchPrimaryUnitsHOC';
import { applyDefaultSettings, applySettings } from '@/app/redux/reporting';
import {
  HeadCountReportChart,
  HeadCountReportViewMode,
} from 'src/app/components/Reporting/People/HeadCountReportChart';

export type HeadcountReportEntryMonth = {
  date: string;
  leavers: number;
  joiners: number;
  netChange: number;
  totalCount: number;
};

export type HeadcountReportEntryYear = {
  date: string;
  totalCount: number;
  monthly: HeadcountReportEntryMonth[];
};

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    height: 'calc(100vh - 360px)',
    width: 1100,
  },
  title: {
    marginBottom: theme.spacing.unit,
  },
  toolsContainer: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px rgba(0, 0, 0, 0.08)',
    padding: theme.spacing.unit,
  },
  leftToolbarButtonMargin: {
    marginLeft: theme.spacing.unit * 2,
  },
  grow: {
    flexGrow: 1,
  },
  noDataOrScope: {
    padding: theme.spacing.unit * 2,
  },
});

/**
 * Headcountsummary API calls must be done with startDate as end of previous year, but UI should function as start of first
 * @param settings
 * @param convertMode
 */
const convertReportSettingsScopeRangeStartDate = (
  settings: IReportSettings,
  convertMode: 'from-end-of-previous' | 'from-start-of-first',
): IReportSettings => {
  const currentStart =
    !!settings.scope &&
    !!settings.scope.range &&
    !!settings.scope.range.startDate
      ? settings.scope.range.startDate
      : undefined;

  if (!currentStart) {
    return settings;
  }

  const isStartOfYear = currentStart.isSame(
    currentStart.clone().startOf('year'),
    'date',
  );
  const isEndOfYear = currentStart.isSame(
    currentStart.clone().endOf('year'),
    'date',
  );

  const resolveStartDate = (date: moment.Moment) => {
    switch (convertMode) {
      case 'from-end-of-previous':
        return isEndOfYear ? date.add(1, 'year').startOf('year') : date;
      case 'from-start-of-first':
        return isStartOfYear ? date.subtract(1, 'year').endOf('year') : date;
      default:
        return date;
    }
  };

  return {
    ...settings,
    scope: {
      ...settings.scope,
      range: {
        ...settings.scope.range,
        startDate: resolveStartDate(currentStart),
      },
    },
  };
};

const defaultSettings: IReportSettings = {
  report: 'headcountsummary',
  scope: {
    type: '',
    range: {
      startDate: moment()
        .startOf('year')
        .subtract(2, 'year'),
      endDate: moment().endOf('year'),
      minDate: moment()
        .startOf('year')
        .subtract(9, 'years'),
      maxDate: moment().endOf('year'),
    },
  },
  custom: {
    viewMode: 'monthly_emp_count',
  },
};

type DispatchProps = {
  settings: IReportSettings;
  data: Array<any>;
  isFetching: boolean;
  primaryUnits: Array<object>;
  myManagedUnits: Array<object>;
  myHrUnits: Array<object>;
  fetchManagedUnits: (fullUnitList: Array<object>) => any;
  fetchHrUnits: (fullUnitList: Array<object>) => any;
  saveSettings: (
    newSettings: IReportSettings,
    mapDataFn: (origData: any) => any,
  ) => void;
  saveDefaultSettings: (defaultSettings: IReportSettings) => void;
};
type OwnProps = {};
type InnerProps = WithStyles<typeof styles> & DispatchProps;
type Props = InnerProps & OwnProps;

const HeadCountReport = (props: Props) => {
  const {
    classes,
    data,
    isFetching,
    primaryUnits,
    myManagedUnits,
    myHrUnits,
    fetchManagedUnits,
    fetchHrUnits,
    saveSettings,
    saveDefaultSettings,
  } = props;

  const settings = React.useMemo(
    () =>
      convertReportSettingsScopeRangeStartDate(
        props.settings,
        'from-end-of-previous',
      ),
    [props.settings],
  );

  const [viewMode, setViewMode] = React.useState<HeadCountReportViewMode>(
    HeadCountReportViewMode.MonthlyEmployeeCount,
  );
  const [scopeSelectorAnchorEl, setScopeSelectorAnchorEl] = React.useState<
    HTMLElement | undefined
  >();
  const [hasTeam, setHasTeam] = React.useState<boolean>();
  const [isLocked, setIsLocked] = React.useState(false);

  React.useEffect(() => {
    saveDefaultSettings(defaultSettings);
    handleSettingsChanged(
      convertReportSettingsScopeRangeStartDate(
        defaultSettings,
        'from-start-of-first',
      ),
    );
  }, [defaultSettings]);

  React.useEffect(() => {
    setHasTeam(currentUserHasTeam());
  }, [setHasTeam]);

  React.useEffect(() => {
    if (!primaryUnits || primaryUnits.length === 0) {
      return;
    }
    fetchManagedUnits(primaryUnits);
  }, [primaryUnits, fetchManagedUnits]);

  React.useEffect(() => {
    if (!primaryUnits || primaryUnits.length === 0) {
      return;
    }
    fetchHrUnits(primaryUnits);
  }, [primaryUnits, fetchHrUnits]);

  const handleMapData = (origData: Array<HeadcountReportEntryYear>) => {
    return origData.map((year: any) => ({
      date: year.fEhdDate,
      totalCount: year.fEhdTotalCount,
      monthly: year.fEhdMonthlyCounts.map((month: any) => ({
        date: month.fEheDate,
        leavers: month.fEheLeavers,
        joiners: month.fEheJoiners,
        netChange: month.fEheJoiners - month.fEheLeavers,
        totalCount: month.fEheCount,
      })),
    }));
  };

  const handleSettingsChanged = React.useCallback(
    (newSettings: IReportSettings) => {
      saveSettings(newSettings, handleMapData);
    },
    [],
  );

  const config: IReportConfig = {
    id: 'headcountsummary',
    report: 'headcountsummary',
    titleLabel: 'laHeadCountReport',
    access: 'standard',
    columns: [],
    filters: {
      scopeFilter: {
        enabled: true,
        accessRights: {
          company: ['HR_ADMIN'],
        },
      },
      yearsAndEndDateFilter: {
        enabled: true,
      },
      customFilters: [
        {
          type: 'BUTTONGROUP',
          params: {
            buttonLabel: '',
            fieldName: '',
            operator: '',
            selectedSettingName: 'viewMode',
            buttons: [
              { label: translate.t('laEmpCount'), value: 'monthly_emp_count' },
              {
                label: translate.t('laJoinersAndLeavers'),
                value: 'monthly_joiners_leavers',
              },
            ],
          },
        },
      ],
    },
  };

  const handleScopeSelectorClose = () => {
    if (isLocked) {
      return;
    }

    setScopeSelectorAnchorEl(undefined);
  };

  const handleApplyScope = (newScope: ScopeType) => {
    const newSettings = Object.assign({}, settings);
    newSettings.scope = newScope;
    handleSettingsChanged(
      convertReportSettingsScopeRangeStartDate(
        newSettings,
        'from-start-of-first',
      ),
    );
    setScopeSelectorAnchorEl(undefined);
  };

  const getUnitName = (unitId: string): string => {
    let unit: any = myManagedUnits.find(
      (item: any) => item.fTreeUnitId === unitId,
    );
    if (!unit) {
      unit = myHrUnits.find((item: any) => item.fTreeUnitId === unitId);
      if (!unit) {
        return '';
      }
    }
    return unit.fTreeUnitName;
  };

  const getScopeSelectorDescription = (): string => {
    if (!settings.scope || settings.scope.type === '') {
      return translate.t('label_none');
    }

    switch (settings.scope.type) {
      case 'manager':
        return translate.t('label_myteam');
      case 'unit':
        if (settings.scope.rln === 'all') {
          return translate.t('label_unit_with_subunits', {
            unitName: getUnitName(settings.scope.id),
          });
        } else {
          return getUnitName(settings.scope.id);
        }
      default:
        return translate.t('label_whole_company');
    }
  };

  const getScopeDateRangeDescription = (): string => {
    if (!settings.scope) {
      return null;
    }
    if (settings.scope.range) {
      const years =
        settings.scope.range.endDate.diff(
          settings.scope.range.startDate,
          'years',
        ) + 1;
      if (settings.scope.range.startDate && settings.scope.range.endDate) {
        const range = settings.scope.range.startDate.isSame(
          settings.scope.range.endDate,
          'year',
        )
          ? settings.scope.range.endDate.format('YYYY')
          : `${settings.scope.range.startDate.format(
              'YYYY',
            )} - ${settings.scope.range.endDate.format('YYYY')}`;

        return `${range} (${years} ${translate
          .t('laYears')
          .toLocaleLowerCase()})`;
      }
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {translate.t(config.titleLabel)}
      </Typography>
      <Grid
        className={classes.toolsContainer}
        container={true}
        justify="space-between"
        alignItems="center"
      >
        <Grid item={true}>
          <Popover
            anchorEl={scopeSelectorAnchorEl}
            open={Boolean(scopeSelectorAnchorEl)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            elevation={4}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleScopeSelectorClose}
          >
            <ScopeSelector
              scope={settings.scope}
              allowCompanyScope={
                settings.allowCompanyScopeForAll === true || hasRole('HR_ADMIN')
              }
              hasTeam={hasTeam}
              myHrUnits={myHrUnits}
              myManagedUnits={myManagedUnits}
              dateRangeVariant="year-range"
              onApply={handleApplyScope}
              onCancel={handleScopeSelectorClose}
              onPopupLock={shouldLock => setIsLocked(shouldLock)}
            />
          </Popover>
          <ToolbarFilterButton
            prompt={translate.t('label_scope')}
            value={getScopeSelectorDescription()}
            value2={getScopeDateRangeDescription()}
            onClick={(e: React.SyntheticEvent<HTMLButtonElement>) =>
              setScopeSelectorAnchorEl(
                eventTargetAsHtmlElement(e.currentTarget),
              )
            }
          />
          <ToolbarFilterButtonGroup
            className={classes.leftToolbarButtonMargin}
            buttons={[
              {
                label: translate.t('laNumberOfEmp'),
                value: HeadCountReportViewMode.MonthlyEmployeeCount,
              },
              {
                label: translate.t('laJoinersAndLeavers'),
                value: HeadCountReportViewMode.MonthlyJoinersAndLeavers,
              },
              {
                label: translate.t('laTable'),
                value: HeadCountReportViewMode.MonthlyTable,
              },
            ]}
            selectedButton={viewMode}
            onSelect={mode => setViewMode(mode as HeadCountReportViewMode)}
          />
        </Grid>
        {/* <Grid item={true}>
          <Button>
            Export
          </Button>
        </Grid> */}
      </Grid>
      <HeadCountReportChart
        viewMode={viewMode}
        data={data}
        loading={isFetching}
      />
    </div>
  );
};

const mapStateToProps = (state: ReducerState) => ({
  primaryUnits: state.orgTree.get('primaryUnits'),
  myManagedUnits: state.currentUser.get('myManagedUnits'),
  myHrUnits: state.currentUser.get('myHrUnits'),
  isFetching: state.reporting.get('isFetching'),
  settings: state.reporting.get('settings'),
  data: state.reporting.get('data'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchManagedUnits: (fullUnitList: Array<object>) =>
    dispatch<any>(fetchMyManagedUnits(fullUnitList)),
  fetchHrUnits: (fullUnitList: Array<object>) =>
    dispatch<any>(fetchMyHrUnits(fullUnitList)),
  saveSettings: (
    newSettings: IReportSettings,
    mapDataFn: (origData: any) => any,
  ) => dispatch<any>(applySettings(newSettings, mapDataFn)),
  saveDefaultSettings: (newDefaultSettings: IReportSettings) =>
    dispatch<any>(applyDefaultSettings(newDefaultSettings)),
});

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
  FetchPrimaryUnitsHOC,
);

export default enhance(HeadCountReport);
