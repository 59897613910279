import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import GenericDialog from '../../GenericDialog/GenericDialog';
import SortableComponent from '../../SortableComponent/SortableComponent';
import translate from '@/app/utils/translate';
import styles from './styles';
import { ICollaborationTemplate, ICollaborationTemplateTask } from '../types';

interface OwnProps {
  open: boolean;
  title: string;
  isDisabledSave: boolean;
  templateDetails: ICollaborationTemplate;
  onClose: () => void;
  onSave: (templateId: number, values: ICollaborationTemplateTask[]) => void;
}

type PropsType = OwnProps & WithStyles<typeof styles>;

const SortableTaskDialog: React.FC<PropsType> = ({ open, title, isDisabledSave, classes, templateDetails, onClose, onSave }) => {
  const taskList = templateDetails.tasks || [];

  const [orderedTaskList, setOrderedTaskList] = useState<ICollaborationTemplateTask[]>([]);

  useEffect(() => {
    if (open) {
      const sortedTaskList = taskList
        .sort((a, b) => a.seq - b.seq)
        .map((task) => ({ ...task, name: task.title }));
      setOrderedTaskList(sortedTaskList);
    }
  }, [open, taskList]);

  const handleUpdateList = (temp: any[]) => {
    setOrderedTaskList(temp);
  };

  const handleSave = () => {
    const sortedTaskList = orderedTaskList.map((task, idx) => ({ ...task, seq: idx + 1 }));
    onSave(templateDetails.id, sortedTaskList);
  };

  return (
    <GenericDialog
      open={open}
      title={title}
      onClose={onClose}
      onSave={handleSave}
      paperWidthMd={classes.paperWidthMd}
      description={translate.t('laSortIns')}
      isDisabledSave={isDisabledSave}
    >
      <SortableComponent list={orderedTaskList} updatedList={handleUpdateList} />
    </GenericDialog>
  );
};

const enhance = compose<any, OwnProps>(
  withStyles(styles, { withTheme: true }),
);

export default enhance(SortableTaskDialog);