import React from 'react';
import Select, { Async as SelectAsync } from 'react-select';

import { FieldProps, FormikProps, FormikValues } from 'formik';
import { WithStyles, withStyles } from '@material-ui/core';

import Control from './formikSelectField/Control';
import customStyles from './formikSelectField/selectCustomStyles';
import styles from './formikSelectField/styles';

const components = {
  Control,
};

export type OptionType = {
  label: string;
  value: string;
};
type OwnProps = {
  clearable?: boolean;
  label?: string;
  // fullWidth?: boolean;
  required?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  options: OptionType[];
  onLoadOptions?: (searchTerm: string) => Promise<any>;
  onChange?: (formikBag: FormikProps<FormikValues>, newValue: OptionType | OptionType[]) => void;
};
type InnerProps = WithStyles<typeof styles> & FieldProps;
type Props = OwnProps & InnerProps;

const SelectField = ({
  classes,
  clearable = false,
  // fullWidth = false,
  field,
  form,
  label = '',
  options,
  required = false,
  isMulti = false,
  disabled = false,
  onLoadOptions,
  onChange,
  ...rest
}: Props) => {
  const handleBlur = (e: React.FocusEvent) => {
    form.setFieldTouched(field.name, true);
    form.handleBlur(e);
  };

  const handleChange = (newValue: OptionType | OptionType[]) => {
    form.setFieldValue(field.name, newValue);
    if (onChange) {
      onChange(form, newValue);
    }
  };

  const textFieldProps = {
    label,
    required,
    // fullWidth,

    InputLabelProps: {
      htmlFor: field.name,
      shrink: true,
    },
  };

  const errorText = (form.errors[field.name] && form.touched[field.name]) && form.errors[field.name];
  const renderError = () => !(form.errors[field.name] && form.touched[field.name]) ? undefined : (
    <span className={classes.colorSpan}>
      {errorText}
    </span>
  );

  const props = {
    // className: classes.grow,
    classes,
    components,
    fullWidth: true,
    isClearable: clearable,
    isDisabled: disabled,
    name: field.name,
    options,
    isMulti,
    styles: customStyles,
    TextFieldProps: textFieldProps,
    value: field.value,
    onBlur: handleBlur,
    onChange: handleChange,
    menuPortalTarget: document.body,
    ...rest
  };

  let select;
  if (!onLoadOptions) {
    select = <Select {...props} />;
  } else {
    select = <SelectAsync {...props} loadOptions={onLoadOptions} />;
  }

  return (
    <>
      {select}
      {renderError()}
    </>
  );
};

export default withStyles(styles)(SelectField);

// export { default as FormikSelectField } from './formikSelectField/Select';
// export { default as FormikSelectAsyncField } from './formikSelectField/SelectAsync';
